import React, { Component } from "react";
import { Button } from "react-bootstrap";

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.state,
      onClick: props.onClick,
      classBody:
        this.props.classBody && this.props.classBody !== null
          ? this.props.classBody
          : "bodyModal",
    };
  }

  Active = () => {
    this.setState({ active: "is-active" });
    if (this.props.isCambiarEstado) {
      this.props.cambiarEstadoModal(true);
    }
  };

  Desactive = (aceptar) => {
    this.setState({ active: "" });
    if (this.props.isCambiarEstado) {
      this.props.cambiarEstadoModal(false);
    }
    if (this.props.isAceptar_tache) {
      if (aceptar != 1) {
        this.props.accion_aceptar();
      }
    }
    if (!this.props.showButton && this.props.tipo === "1") {
      this.props.accion_aceptar();
    }
    if (!this.props.showButton && this.props.tipo === "2") {
      this.props.Desactive();
    }
  };

  isIcono = () => {
    if (this.props.isIcono) {
      return (
        <span class={"icoNo " + this.props.tamaño}>
          <ion-icon name={this.props.icon_ion}></ion-icon>
        </span>
      );
    }
  };
  isText = () => {
    if (this.props.isText) {
      return <p>{this.props.boton_texto}</p>;
    }
  };

  showButton = (activa) => {
    if (activa) {
      return (
        <Button
          variant={this.props.variant}
          onClick={this.Active}
          className={this.props.estilo_className + " " + this.props.tamaño}
        >
          {this.isText()}
          {this.isIcono()}
        </Button>
      );
    }
  };

  tipoModal(tipo) {
    switch (tipo) {
      case "2":
        return (
          <>
            <button
              class="btrn3"
              onClick={() => {
                this.props.accion_aceptar();
                this.Desactive();
              }}
            >
              Aceptar
            </button>
            <button class="btrn3c" onClick={this.Desactive}>
              Cancelar
            </button>
          </>
        );

      case "1":
        return (
          <>
            <button class="btrn3" onClick={this.Desactive}>
              Aceptar
            </button>
          </>
        );
      case "3":
        return (
          <>
            <button
              class="btrn3"
              onClick={() => {
                this.props.accion_aceptar();
                this.Desactive(1);
              }}
            >
              Aceptar
            </button>
          </>
        );
      case "4":
        return (
          <>
            <button
              class="btrn3"
              onClick={() => {
                this.props.accion_aceptar();
                this.Desactive(1);
              }}
              type="submit"
            >
              Enviar
            </button>
          </>
        );
      case "5":
        return (
          <>
            <button
              class="btrn3"
              onClick={() => {
                this.props.accion_aceptar();
                this.Desactive();
              }}
            >
              Aceptar
            </button>
            <button class="btrn3c" onClick={this.Desactive}>
              Cancelar
            </button>
          </>
        );
      default:
        break;
    }
  }
  /*
En los props debe que contener:
variant: Para el color del boton - boostrap,
icon_ion: Nombre del icono sacado de la pagina ion
estilo_className: Estilo del boton, ej. redondo (is-rounded) - bulma
tamaño: Tamaño del boton ej. pequeño (is-small) - bulma 
tipo: 2. (botones: [Aceptar][Cancelar]) 1. (botones: [Aceptar])
accion_aceptar: Accion que realizara cuando de en aceptar en el tipo 2, recive una funcion
  */

  showButtonA = () => {
    if (this.props.showButton === true) {
      return this.state.active;
    } else {
      return this.props.stateModal;
    }
  };

  render() {
    return (
      <>
        {this.showButton(this.props.showButton)}
        <div
          className={"modal " + this.showButtonA()}
          key={new Date().getTime()}
        >
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="hemodal">
              <p className="">{this.props.titulo}</p>
              <button className="close" onClick={this.Desactive}></button>
            </header>
            <section className={this.state.classBody}>
              {this.props.descripcion}
            </section>
            <footer className="fomodal cenItem">
              <>{<>{this.tipoModal(this.props.tipo)}</>}</>
            </footer>
          </div>
        </div>{" "}
      </>
    );
  }
}
