import React, { Component } from "react";
import { Form } from "react-bootstrap";

export default class inputTextLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isChange: false,
      vChangeExterno: props.vChangeExterno,
    };
  }

  change = (evt) => {
    if (evt.target.name === "url_mapa_admin_conf") {
      var value = evt.target.value;
      var expresionRegular = /\s*"\s*/;
      var url_mapa = value.split(expresionRegular);
      this.setState({
        value: url_mapa[1] === undefined ? evt.target.value : url_mapa[1],
        isChange: true,
      });
      if (this.state.vChangeExterno !== undefined) {
        var evtTmp = {
          target: {
            value: url_mapa[1] === undefined ? evt.target.value : url_mapa[1],
            name: evt.target.name,
          },
        };
        this.state.vChangeExterno(evtTmp);
      }
    } else {
      if (this.state.vChangeExterno === undefined) {
        this.setState({ value: evt.target.value, isChange: true });
      } else {
        this.setState({ value: evt.target.value, isChange: true });
        this.state.vChangeExterno(evt);
      }
    }
  };

  getValue = () => {
    return this.state.isChange ? this.state.value : this.props.value;
  };

  valorDefault = () => {
    if (this.props.id == "no_cuenta_alumno") {
      return this.props.valueNoCuenta;
    } else {
      return this.state.isChange ? this.state.value : this.props.value;
    }
  };

  isRequired = () => {
    if (this.props.obligatoria == "true") {
      if (this.props.entrada == "number") {
        return (
          <Form.Control
            required
            id={this.props.id}
            name={this.props.id}
            type={this.props.entrada}
            placeholder={this.props.placeholder}
            size="sm"
            min="1"
            onChange={this.change}
            value={this.valorDefault()}
          />
        );
      } else {
        return (
          <Form.Control
            required
            id={this.props.id}
            name={this.props.id}
            type={this.props.entrada}
            placeholder={this.props.placeholder}
            size="sm"
            onChange={this.change}
            value={this.valorDefault()}
          />
        );
      }
    } else {
      if (this.props.entrada == "number") {
        return (
          <Form.Control
            id={this.props.id}
            name={this.props.id}
            type={this.props.entrada}
            placeholder={this.props.placeholder}
            size="sm"
            onChange={this.change}
            value={this.valorDefault()}
            min="1"
          />
        );
      } else {
        return (
          <Form.Control
            id={this.props.id}
            name={this.props.id}
            type={this.props.entrada}
            placeholder={this.props.placeholder}
            size="sm"
            onChange={this.change}
            value={this.valorDefault()}
          />
        );
      }
    }
  };

  render() {
    return (
      <Form.Group className="sombra3 pad2">
        <Form.Label>{this.props.pregunta}</Form.Label>
        <div className="hr" />
        {this.isRequired()}
        <Form.Text>{this.props.comentarios}</Form.Text>
        <br />
        <br />
      </Form.Group>
    );
  }
}
