import React, { Component } from "react";

import { Form } from "react-bootstrap";

export default class inputComboBox extends Component {
  opcRequired = (vRespuesta) => {
    if (this.props.value == vRespuesta.opc) {
      return (
        <option
          name={this.props.id}
          id={vRespuesta.id}
          value={vRespuesta.opc}
          selected
        >
          {vRespuesta.opc}
        </option>
      );
    } else {
      return (
        <option name={this.props.id} id={vRespuesta.id} value={vRespuesta.opc}>
          {vRespuesta.opc}
        </option>
      );
    }
  };

  opcsRequired = () => {
    if (this.props.value.length > 0) {
      return (
        <>
          {this.props.respuestas.map((vRespuesta) => (
            <>{this.opcRequired(vRespuesta)}</>
          ))}
        </>
      );
    } else {
      return (
        <>
          <option disabled selected>
            Seleccione...
          </option>

          {this.props.respuestas.map((vRespuesta) => (
            <>
              <option
                name={this.props.id}
                id={vRespuesta.id}
                value={vRespuesta.opc}
              >
                {vRespuesta.opc}
              </option>
            </>
          ))}
        </>
      );
    }
  };

  opcNoRequired = (vRespuesta) => {
    if (this.props.value == vRespuesta.opc) {
      return (
        <option
          name={this.props.id}
          id={vRespuesta.id}
          value={vRespuesta.opc}
          selected
        >
          {vRespuesta.opc}
        </option>
      );
    } else {
      return (
        <option name={this.props.id} id={vRespuesta.id} value={vRespuesta.opc}>
          {vRespuesta.opc}
        </option>
      );
    }
  };

  opcsNoRequired = () => {
    if (this.props.value.length > 0) {
      return (
        <>
          {this.props.respuestas.map((vRespuesta) => (
            <>{this.opcNoRequired(vRespuesta)}</>
          ))}
        </>
      );
    } else {
      return (
        <>
          <option disabled selected>
            Seleccione...
          </option>
          {this.props.respuestas.map((vRespuesta) => (
            <>
              <option
                name={this.props.id}
                id={vRespuesta.id}
                value={vRespuesta.opc}
              >
                {vRespuesta.opc}
              </option>
            </>
          ))}
        </>
      );
    }
  };

  isRequired = () => {
    if (this.props.obligatoria == "true") {
      return (
        <Form.Select
          required
          aria-label="Default select"
          name={this.props.id}
          id={this.props.id}
          lang={this.props.id_compartido}
        >
          {this.opcsRequired()}
        </Form.Select>
      );
    } else {
      return (
        <Form.Select
          aria-label="Default select"
          name={this.props.id}
          id={this.props.id}
          lang={this.props.id_compartido}
        >
          {this.opcsNoRequired()}
        </Form.Select>
      );
    }
  };

  render() {
    return (
      <Form.Group className="sombra3 pad2">
        <Form.Label>{this.props.pregunta}</Form.Label>
        <div className="hr" />
        {this.isRequired()}
        <Form.Text>{this.props.comentarios}</Form.Text>
        <br />
      </Form.Group>
    );
  }
}
