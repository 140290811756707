import React, { Component } from 'react';

import { Form } from 'react-bootstrap';

export default class inputTextArea extends Component {
	state = {
		value: '',
		isChange: false
	};

	change = (evt) => {
		this.setState({ value: evt.target.value, isChange: true });
	};

	getValue = () => {
		return this.state.isChange ? this.state.value : this.props.value;
	};

	isRequired = () => {
		if (this.props.obligatoria == 'true') {
			return (
				<Form.Control
					required
					id={this.props.id}
					name={this.props.id}
					type={this.props.entrada}
					placeholder={this.props.placeholder}
					placeholderStyle={this.styleTextF}
					as="textarea"
					rows={6}
					size="sm"
					onChange={this.change}
					value={this.getValue()}
				/>
			);
		} else {
			return (
				<Form.Control
					id={this.props.id}
					name={this.props.id}
					type={this.props.entrada}
					placeholder={this.props.placeholder}
					placeholderStyle={this.styleTextF}
					as="textarea"
					rows={6}
					size="sm"
					onChange={this.change}
					value={this.getValue()}
				/>
			);
		}
	};

	render() {
		return (
			<Form.Group className="sombra3 pad2">
				<Form.Label>{this.props.pregunta}</Form.Label>
				<div className="hr" />
				{this.isRequired()}
				<Form.Text>{this.props.comentarios}</Form.Text>
				<br />
				<br />
			</Form.Group>
		);
	}
}
