import React, { Component } from "react";

export default class cargando2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCargando: props.isCargando,
    };
  }
  render() {
    return (
      <div class={"modal " + this.state.isCargando}>
        <div class="modal-background"></div>
        <div className="loader2"></div>
      </div>
    );
  }
}
