import React, { Component } from "react";

export default class noInfor extends Component {
  render() {
    return (
      <div className="sombra3">
        <h2 className="titleMod2">
          No hay existencia de formularios, empiece a crear un nuevo trámite
        </h2>
        <div className="cen">
          <img src="https://img.icons8.com/plumpy/100/000000/create.png" />
        </div>
      </div>
    );
  }
}
