import React, { Component } from "react";
import { Button } from "react-bootstrap";

import CardInfo from "../../../../Componente/showInfo/CardInfo";
import VideoPlayer from "../../../../Componente/video/videoPalyer";
import Modal from "../../../../Componente/ModalForm/Text_modalNoCuenta";
import NoTramites from "../../../../Componente/noTramites/noTramitesAlumno";

export default class Tramites extends Component {
  state = {
    key: Date.now(),
    active: "",
    tramite: "",
  };

  Active = () => {
    this.setState({ active: "is-active", key: Date.now() });
  };

  Desactive = () => {
    this.setState({ active: "" });
  };

  cuerpo_ModalInfo = (tramite) => (
    <>
      <p
        dangerouslySetInnerHTML={{
          __html: tramite.contenido.info_tramite.descripcion_tramite,
        }}
      />
      <br />
      <br />
      {tramite.contenido.info_tramite.video_tramite.length ? (
        <>
          <h3></h3>
          <br />
          <VideoPlayer url={tramite.contenido.info_tramite.video_tramite} />
        </>
      ) : (
        <></>
      )}
    </>
  );

  botonCardFooter = (texto_boton, tramiteN) => (
    <Button
      className="btrn"
      variant="outline-info"
      onClick={() => {
        this.Active();
        this.setState({ tramite: tramiteN });
      }}
    >
      {texto_boton}
    </Button>
  );

  modalForm = () => (
    <Modal
      titulo={<>Escriba su número de cuenta </>}
      variant="outline-warning"
      icon_ion="duplicate-outline"
      estilo_className="is-rounded"
      tamaño="is-small"
      direccion="column"
      boton_texto="TextArea"
      accion_aceptar={() =>
        this.props.cambiarPathF("Formulario", this.state.tramite)
      }
      cambiarNoCuenta={this.props.cambiarNoCuenta}
      stateModal={this.state.active}
      Desactive={this.Desactive}
      key={this.state.key}
    />
  );

  renderCardInfo = (tramite) => (
    <>
      <CardInfo
        tituloCard={tramite.contenido.info_tramite.nombre_tramite}
        cuerpoCard={
          <p
            dangerouslySetInnerHTML={{
              __html: tramite.contenido.info_tramite.descripcionBreve_tramite,
            }}
          />
        }
        texto_boton="Ir al trámite"
        titulo_ModalInfo={tramite.contenido.info_tramite.nombre_tramite}
        cuerpo_ModalInfo={this.cuerpo_ModalInfo(tramite)}
        cambiarPathF={this.props.cambiarPathF}
        tramite={tramite}
        cambiarEstadoModal={this.props.cambiarEstadoModal}
        isCambiarEstado={true}
        isBotonDefault={false}
        botonCardFooter={this.botonCardFooter}
      />
    </>
  );

  existe_Tramites = () => {
    if (this.props.tramites.length > 0) {
      return (
        <div className="grid2 sombra2">
          {this.props.tramites.map((tramite) => (
            <ts className="">{this.renderCardInfo(tramite)}</ts>
          ))}
        </div>
      );
    } else {
      return <NoTramites />;
    }
  };

  render() {
    return (
      <div className="contenedor">
        <div className="sombra2">
          <h1 class="titleMod">Trámites</h1>
        </div>
        {this.existe_Tramites()}
        {this.modalForm()}
      </div>
    );
  }
}
