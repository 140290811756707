/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";

import { elastic as Menu } from "react-burger-menu";

import Burger from "../../../Componente/showInfo/burger";

export default class Header_Alumno_Pc extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Burger
          visibleBurger={this.props.visibleBurger}
          cambiarPathN={this.props.cambiarPathN}
          isFormulario={this.props.isFormulario}
          ubicacion={this.props.ubicacion}
        />
        <div className="sombra">
          <img src="img/logo_uaemamex.png" width="250px" />
          <h1 className="titleMod1">{this.props.pag_info.nombre_app}</h1>
        </div>
      </>
    );
  }
}
