/* eslint-disable react/jsx-pascal-case */
import React from "react";

import Helmet from "react-helmet";
import {
  BrowserRouter as Rutas,
  Route as Ruta,
  Switch,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.min.css";
import "./css/normalize.css";
import "./css/style_Alumno.css";
import "./css/styles_Admin.css";

import Interfaz_Alumno from "./Interfaz/Alumno/Inicio_alumno/Interfaz_Alumno";
import Home_Admin from "./Interfaz/Admin/Home_Admin";

function Home() {
  localStorage.setItem("recargo", "si");
  localStorage.setItem("verificando", "0");
  return (
    <div className="App">
      <Helmet>
        <script
          type="module"
          src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
        ></script>
        <script
          nomodule
          src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"
        ></script>
      </Helmet>
      <header className="App-header">
        <Rutas>
          <Switch>
            <Ruta exact path="/" component={Interfaz_Alumno} />
            <Ruta exact path="/triton">
              <Home_Admin frame="init" />
            </Ruta>
          </Switch>
        </Rutas>
      </header>
    </div>
  );
}

export default Home;
