import React, { Component } from "react";

import { Form } from "react-bootstrap";

import "react-dates/initialize";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/lib/css/_datepicker.css"; 
import moment from "moment";
import "moment/locale/es-mx";

export default class inputDate extends Component {
  state = {
    date: "",
    focused: true,
    isChange: false,
  };

  async componentDidMount() {}

  isRequired = (dateString) => {
    if (this.props.obligatoria === "true") {
      return (
        <input
          required
          type="hidden"
          name={this.props.id}
          id={this.props.id}
          value={dateString || "Escriba la fecha por no seleccionar una fecha"}
        />
      );
    } else {
      return (
        <input
          type="hidden"
          name={this.props.id}
          id={this.props.id}
          value={dateString || "Escriba la fecha por no seleccionar una fecha"}
        />
      );
    }
  };

  render() {
    moment.locale("es-mx");
    const { date } = this.state;
    const dateString = date && date.format("DD-MM-YYYY");

    var mI = 0;
    var yI = 0;

    if (!this.state.isChange) {
      if (
        moment(this.props.value, "DD-MM-YYYY").format("D") !== "Fecha inválida"
      ) {
        mI =
          moment(this.props.value, "DD-MM-YYYY").format("M") -
          moment().format("M");
        yI =
          moment(this.props.value, "DD-MM-YYYY").format("YYYY") -
          moment().format("YYYY");
        this.setState({
          date: moment(this.props.value, "DD-MM-YYYY"),
          isChange: true,
        });
        this.isRequired(
          this.state.date && this.state.date.format("DD-MM-YYYY")
        );
      }
    }

    return (
      <Form.Group className="sombra4 pad2">
        <Form.Label>{this.props.pregunta}</Form.Label>
        <div className="hr" />
        {this.isRequired(dateString)}
        <div className="cenItem">
          <DayPickerSingleDateController
            key={this.props.id}
            name={this.props.id}
            id={this.props.id}
            date={this.state.date}
            onDateChange={(date) => this.setState({ date })}
            focused={this.state.focused}
            initialVisibleMonth={() => moment().add(mI, "M").add(yI, "Y")}
            onFocusChange={({ focused }) =>
              this.setState({ focused, isChange: true })
            }
            numberOfMonths="1"
            calendarInfoPosition="top"
          />
        </div>
        <Form.Text>{this.props.comentarios}</Form.Text>
        <br />
      </Form.Group>
    );
  }
}
