/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";

export default class Header_Alumno_Pc extends Component {
  render() {
    return (
      <div className="sombra contenedor pad2 gridFoo badk">
        <div class="informacion__Encargado">
          <p>{this.props.pag_info.nombre_jefe}</p>
          <p>{this.props.pag_info.correo_electronico_contacto}</p>
        </div>
        <img
          class="imagen__Escudo"
          src="img/logo_uaemamex1.png"
          width="300px"
        />
        <div class="informacion__Unidad">
          <p>{this.props.pag_info.nombre_universidad}</p>
          <p>Tecnologinc</p>
        </div>
      </div>
    );
  }
}
