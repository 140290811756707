import React, { useMemo, Component } from "react";

import { useDropzone } from "react-dropzone";

import { Form } from "react-bootstrap";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

async function myCustomFileGetter(event, actualizar_archivos_seleccionados) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;
  for (var i = 0; i < fileList.length; i++) {
    files.push(fileList.item(i));
  }
  actualizar_archivos_seleccionados(files);
  return files;
}

function StyledDropzone(props) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    getFilesFromEvent: (event) =>
      myCustomFileGetter(event, props.actualizar_archivos_seleccionados),
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fileNombre = () => {
    if (acceptedFiles.length <= 0) {
      return props.archivos_seleccionados.map((file) => (
        <>
          <p className="box" key={file.nombre}>
            {file.nombre} - {file.tamanio} bytes
          </p>
        </>
      ));
    } else {
      return acceptedFiles.map((file) => (
        <>
          <p className="box" key={file.name}>
            {file.name} - {file.size} bytes
          </p>
        </>
      ));
    }
  };

  const onClick = () => {
    if (props.onClick !== undefined) {
      props.onClick();
    }
  };

  return (
    <section>
      <div {...getRootProps({ style })}>
        <input
          {...getInputProps()}
          onClick={() => {
            onClick();
          }}
        />
        <p>
          Arrastre aqui el archivo .docx o de click para seleccionar el archivo
          con sus respectivos identificadores ya dentro
        </p>
      </div>
      <aside className="">
        <h1 style={{ fontSize: "18px" }}>
          {" "}
          <strong>Archivos seleccionados: </strong>
        </h1>
        <br />
        <>{fileNombre()}</>
      </aside>
    </section>
  );
}

export default class inputFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicado: false,
      onClick: props.onClick,
    };
  }

  cambiarClicado = (vEstado) => {
    this.setState({ clicado: vEstado });
  };

  render() {
    return (
      <Form.Group className="box styleBoxP">
        <StyledDropzone
          clicado={this.state.clicado}
          cambiarClicado={this.cambiarClicado}
          actualizar_archivos_seleccionados={
            this.props.actualizar_archivos_seleccionados
          }
          archivos_seleccionados={this.props.archivos_seleccionados}
          onClick={this.state.onClick}
        />
      </Form.Group>
    );
  }
}
