import React, { Component } from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import InputTextLabel from "../../Componente/inputsForm/inputTextFile";
import InputTextArea from "../../Componente/inputsForm/inputTextArea";
import InputEditorText from "../../Componente/inputsForm/inputEditorText";
import Modal from "../../Componente/showInfo/Modals/Modal";
import Cargando from "../../Componente/cargando_info/cargando";

import * as Api from "../../utils/api";

export default class Config_Pagina extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pag_info: {},
      active: "",
      isCargando: true,
      descripcion_acercade: "",
    };
  }

  async componentDidMount() {
    Api.readJSON("1").then((data) => {
      switch (data["estado"]) {
        case "400":
        case "404":
        case "no sesion":
          this.props.CambiarFrame("login");
          break;
        default:
          this.setState({
            pag_info: data["datos"],
            isCargando: false,
          });
          break;
      }
    });
  }

  handleSubmit = () => {
    Api.escribirpag_info(this.state.pag_info).then((data) => {});
    this.cambiarEstadoModal(true);
  };

  cambiarInfoFormulario = (evt) => {
    if (evt.target) {
      pag_info = this.state.pag_info;
      if (evt.target.name == "url_mapa_admin_conf") {
        pag_info.url_maps = evt.target.value;
      } else {
        pag_info[evt.target.name] = evt.target.value;
      }
      this.setState({ pag_info });
    } else {
      var pag_info = this.state.pag_info;
      pag_info[evt.name] = evt.value;
      this.setState({ pag_info });
    }
  };

  cambiarEstadoModal = (estate) => {
    this.setState({ active: estate ? "is-active" : "" });
  };

  mCabecera = () => (
    <div className="contenedor sombra pad gridF">
      <h1 className="titleMod1">Configuración de la página</h1>
      <OverlayTrigger
        trigger="hover"
        key="left"
        placement="bottom"
        overlay={
          <Popover id={`popover-positioned-left`}>
            <Popover.Header as="h3">Regresar</Popover.Header>
            <Popover.Body>
              Al salir de la configuración{" "}
              <strong>
                los cambios no guardados seran perdidos para siempre
              </strong>
              , de clic aqui.
            </Popover.Body>
          </Popover>
        }
      >
        <div className="cenItem">
          <button
            className="btrn5"
            onClick={() => this.props.CambiarFrame("config_tramites")}
          >
            <span class={"icon "}>
              <ion-icon name="close" />
            </span>
          </button>
        </div>
      </OverlayTrigger>
    </div>
  );

  render() {
    if (!this.state.isCargando) {
      return (
        <div className="contenedor">
          {this.mCabecera()}
          <>
            <div className="sombra2">
              <div className="contenedor2">
                <InputTextLabel
                  id="nombre_app"
                  obligatoria="true"
                  pregunta="Nombre de la aplicación web:"
                  entrada="text"
                  placeholder=""
                  comentarios="Esta información se mostrará en el encabezado de la pagina"
                  value={this.state.pag_info.nombre_app}
                  vChangeExterno={this.cambiarInfoFormulario}
                />
                <InputTextLabel
                  id="nombre_jefe"
                  obligatoria="true"
                  pregunta="Nombre del Jefe del Departamento de Servicio Social:"
                  entrada="text"
                  placeholder=""
                  comentarios="Esta información se mostrará en el pie de página:"
                  value={this.state.pag_info.nombre_jefe}
                  vChangeExterno={this.cambiarInfoFormulario}
                />
                <InputTextLabel
                  id="correo_electronico_contacto"
                  obligatoria="true"
                  pregunta="Correo electrónico de contacto:"
                  entrada="text"
                  placeholder=""
                  comentarios="Esta información se mostrará en el pie de página"
                  value={this.state.pag_info.correo_electronico_contacto}
                  vChangeExterno={this.cambiarInfoFormulario}
                />
                <InputTextLabel
                  id="nombre_universidad"
                  obligatoria="true"
                  pregunta="Nombre completo de la universidad:"
                  entrada="text"
                  placeholder=""
                  comentarios="Esta información se mostrará en el pie de página"
                  value={this.state.pag_info.nombre_universidad}
                  vChangeExterno={this.cambiarInfoFormulario}
                />
                <InputTextLabel
                  id="abreviatura_nombre_universidad"
                  obligatoria="true"
                  pregunta="Abreviatura del nombre de la universidad:"
                  entrada="text"
                  placeholder=""
                  comentarios="Esta información se mostrará en la sección Acerca De"
                  value={this.state.pag_info.abreviatura_nombre_universidad}
                  vChangeExterno={this.cambiarInfoFormulario}
                />

                <InputEditorText
                  titulo="Descripción Acerca De"
                  name="descripcion_acercade"
                  value={this.state.pag_info.descripcion_acercade}
                  cambiarInfoFormulario={this.cambiarInfoFormulario}
                />
                <InputEditorText
                  titulo="Dirección de la institución"
                  name="direccion_institucion"
                  value={this.state.pag_info.direccion_institucion}
                  cambiarInfoFormulario={this.cambiarInfoFormulario}
                />
                <InputEditorText
                  titulo="Oficina del Servicio Social"
                  name="direccion_oficina"
                  value={this.state.pag_info.direccion_oficina}
                  cambiarInfoFormulario={this.cambiarInfoFormulario}
                />
                <InputTextLabel
                  id="url_mapa_admin_conf"
                  obligatoria="true"
                  pregunta="Url del mapa"
                  entrada="text"
                  placeholder=""
                  comentarios="Esta información se mostrará en la página de Acerca De"
                  value={this.state.pag_info.url_maps}
                  vChangeExterno={this.cambiarInfoFormulario}
                />
                <div className="cenItem">
                  <button
                    type="submit"
                    class="btrn4"
                    onClick={this.handleSubmit}
                  >
                    Guardar cambios
                  </button>
                </div>
              </div>
            </div>
          </>
          <Modal
            showButton={false}
            isIcono={false}
            variant="success"
            tamaño="is-medium"
            tipo="3"
            isAceptar_tache={true}
            accion_aceptar={() => this.props.CambiarFrame("config_tramites")}
            boton_texto="Guardar tramite"
            isText={true}
            titulo="Configuración guardada exitosamente"
            descripcion="Se guardó y modificó exitosamente la información de la página y apartados, de clic en [Aceptar] para ser redireccionado a los trámites."
            cambiarEstadoModal={this.cambiarEstadoModal}
            isCambiarEstado={true}
            stateModal={this.state.active}
          />
        </div>
      );
    } else {
      return (
        <div className="contenedor">
          {this.mCabecera()}
          <Cargando texto="Cargando información, por favor espere." />
        </div>
      );
    }
  }
}
