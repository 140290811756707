import React, { Component } from "react";

import { Accordion } from "react-bootstrap";

import Acordion from "../../../../Componente/showInfo/Acordion";
import VideoPlayer from "../../../../Componente/video/videoPalyer";
import NoTramites from "../../../../Componente/noTramites/noTramitesAlumno";

export default class Ayuda extends Component {
  cuerpo_AcordionInfo = (tramite) => (
    <>
      <p
        dangerouslySetInnerHTML={{
          __html: tramite.contenido.info_tramite.descripcion_tramite,
        }}
      />
      <br />
      <br />
      {tramite.contenido.info_tramite.video_tramite.length ? (
        <>
          <h3></h3>
          <br />
          <VideoPlayer url={tramite.contenido.info_tramite.video_tramite} />
        </>
      ) : (
        <></>
      )}
    </>
  );

  renderCardInfo = (tramite, index) => (
    <Acordion
      activar={index}
      Titulo={
        "Paso " +
        (index + 1) +
        ". " +
        tramite.contenido.info_tramite.nombre_tramite
      }
      contenido={this.cuerpo_AcordionInfo(tramite)}
    />
  );

  existe_Tramites = () => {
    if (this.props.tramites.length > 0) {
      return (
        <div class="sombra2 jus sclp">
          <Accordion>
            {this.props.tramites.map((tramite, index) => (
              <>{this.renderCardInfo(tramite, index)}</>
            ))}
          </Accordion>
        </div>
      );
    } else {
      return <NoTramites />;
    }
  };

  render() {
    return (
      <div className="contenedor">
        <div className="sombra2 ">
          <h1 class="titleMod">Ayuda</h1>
        </div>
        {this.existe_Tramites()}
      </div>
    );
  }
}
