/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";

import * as Api from "../../utils/api";

import Login from "./LogInForm";
import DescargarInfo from "../../Componente/cargando_info/descargandoInfo";
import Config_Tramites from "./Config_Tramites";
import Config_Pagina from "./Config_Pagina";
import Creacion_Admin from "./EditForms/Creacion_Admin";
import AddAcount from "./addAcount";
import Modal from "../../Componente/showInfo/Modals/Modal";

import Cargando from "../../Componente/cargando_info/cargando2";

export default class Home_Admin extends Component {
  state = {
    frame: this.props.frame,
    tramites: [],
    tramiteEdit: null,

    ruta: "",

    tamAltNav: "",
    isCargando: "",
    active: "",
  };

  agregar_EliminarTramites = (vTramitesN, tipo, vTipoAccion, vTramiteDel) => {
    this.mEstadoSesion(false, 4);
    this.mEstadoSesion(false, 4);
    this.setState({ tramites: "" });
    Api.escribirFormatoJSON(vTramitesN, vTramiteDel, vTipoAccion).then(
      (data) => {}
    );
    if (vTipoAccion === "1") {
      Api.eliminarArchivo(vTramiteDel.id).then((data) => {});

      Api.eliminarDB(vTramiteDel).then((data) => {});
    }

    this.setState({
      tramites: vTramitesN,
      tramiteEdit: null,
    });
  };

  CambiarFrame = (frameN, isLogin) => {
    this.setState({
      isCargando: "is-active",
    });
    this.setState({
      frame: frameN,
      tramiteEdit: null,
    });
    this.mEstadoSesion(false, 4);
    this.mEstadoSesion(false, 4);
    if (isLogin) {
      this.mBuscarDatos();
    }
  };

  mBuscarDatos = () => {
    Api.readJSON("2").then((data) => {
      switch (data["estado"]) {
        case "400":
        case "404":
        case "no sesion":
          this.setState({
            frame: "login",
            tramiteEdit: null,
          });
          break;
        default:
          this.setState({
            tramites: data["datos"],
            tramiteEdit: null,
            isCargando: "",
          });
          break;
      }
    });
  };

  CambiarFrameEdit = (frameN, tramiteEditN) => {
    this.setState({
      isCargando: "is-active",
    });
    this.mEstadoSesion(false, 4);
    this.mEstadoSesion(false, 4);
    this.setState({
      frame: frameN,
      tramiteEdit: tramiteEditN,
    });
  };

  mEstadoSesion = (vCambiar, tipo) => {
    Api.mEstadoSesion(tipo).then((data) => {
      localStorage.setItem("verificando", "0");
      this.setState({
        isCargando: "",
      });
      switch (data["estado"]) {
        case "continua":
        case "activo":
          if (vCambiar && localStorage.getItem("recargo") === "si") {
            localStorage.setItem("recargo", "no");
            this.mBuscarDatos();
            this.setState({
              frame: "config_tramites",
              tramiteEdit: null,
            });
          }
          break;
        case "error":
          if (localStorage.getItem("activo") === "1") {
            this.cambiarEstadoModal(true);
            localStorage.setItem("activo", "0");
          }
        default:
          localStorage.removeItem("cookie");
          this.setState({
            frame: "login",
            tramiteEdit: null,
          });
          break;
      }
    });
  };

  renderFrame(frame) {
    switch (frame) {
      case "init":
        this.mEstadoSesion(true, 4);
        break;
      case "login":
        return <Login CambiarFrame={this.CambiarFrame} />;
      case "config_tramites":
        return (
          <>
            <Config_Tramites
              CambiarFrame={this.CambiarFrame}
              CambiarFrameEdit={this.CambiarFrameEdit}
              tramites={this.state.tramites}
              agregar_EliminarTramites={this.agregar_EliminarTramites}
              vKey={Date.now()}
            />
          </>
        );
      case "config_pagina":
        return <Config_Pagina CambiarFrame={this.CambiarFrame} />;
      case "creacion_admin":
        return (
          <Creacion_Admin
            CambiarFrame={this.CambiarFrame}
            agregar_EliminarTramites={this.agregar_EliminarTramites}
            tramites={this.state.tramites}
            tramiteEdit={this.state.tramiteEdit}
            mEstadoSesion={this.mEstadoSesion}
          />
        );
      case "addAcount":
        return <AddAcount CambiarFrame={this.CambiarFrame} />;
      case "descargarInfo":
        return (
          <DescargarInfo
            texto="Descargando datos de los tramites"
            CambiarFrame={this.CambiarFrame}
            tramites={this.state.tramites}
          />
        );
      default:
        break;
    }
  }

  cambiarEstadoModal = (estate) => {
    this.setState({ active: estate ? "is-active" : "" });
  };

  render() {
    //Se estara ejecutando cada 5 min

    var minutos = 2;
    var tiempo = 1000 * (60 * minutos);
    setInterval(() => {
      if (
        (localStorage.getItem("activo") === "1" &&
          localStorage.getItem("verificando") === "0") ||
        (localStorage.getItem("verificando") === "1" &&
          localStorage.getItem("cookie") !== null)
      ) {
        if (localStorage.getItem("verificando") === "0") {
          localStorage.setItem("verificando", "1");
        } else {
          localStorage.setItem("verificando", "2");
        }
        this.mEstadoSesion(false, 3);
      }
    }, tiempo);
    window.addEventListener("beforeunload", function (e) {
      if (
        localStorage.getItem("activo") === "1" &&
        localStorage.getItem("cookie") !== null
      ) {
        var confirmationMessage = "\\o/";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome
      }
    });
    return (
      <>
        {this.renderFrame(this.state.frame)}
        <div key={Date.now()}>
          <Cargando isCargando={this.state.isCargando} />
        </div>
        <Modal
          showButton={false}
          isIcono={false}
          variant="success"
          tamaño="is-medium"
          tipo="3"
          isAceptar_tache={true}
          accion_aceptar={() => {}}
          boton_texto="Aceptar"
          isText={true}
          titulo="Sesión cerrada por inactividad"
          descripcion="Por su seguridad hemos cerrado su sesión, las acciones no guardadas serán omitidas."
          cambiarEstadoModal={this.cambiarEstadoModal}
          isCambiarEstado={true}
          stateModal={this.state.active}
        />
      </>
    );
  }
}
