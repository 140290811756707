import React, { Component } from "react";

import ReactPlayer from "react-player";

// Render a YouTube video player

export default class videoPalyer extends Component {
  state = {
    play: false,
  };

  render() {
    return (
      <div className="sombra3 cenItem">
        <ReactPlayer
          url={this.props.url}
          playing={this.state.play}
          controls={true}
        />
      </div>
    );
  }
}
