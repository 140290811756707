import React, { Component } from "react";

export default class cargando extends Component {
  render() {
    return (
      <div className="sombra3 pad2">
        <h2 className="titleMod2">{this.props.texto}</h2>
        <div className="loader" />
      </div>
    );
  }
}
