/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import Formularios_Disponibles from "../../Componente/showInfo/Ordenar_Opciones/Ordenar_Opciones";
import NoTramites from "../../Componente/noTramites/noTramitesAdmin";
import Modal from "../../Componente/showInfo/Modals/Modal";

import * as Api from "../../utils/api";

export default class Config_Tramites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      opc: "",
      itemCont: 0,
      enable: false,
      vEventoExternoAdd: "",
      active: "",
      key: props.key,
    };
  }

  cambiando = (evt) => {
    this.setState({ opc: evt.target.value });
    if (this.state.opc.trim().length >= 2) {
      this.setState({ enable: true });
    } else {
      this.setState({ enable: false });
    }
  };

  addTramite = (evt) => {
    this.state.vEventoExternoAdd(this.state.opc);

    evt.preventDefault();
    this.setState({
      opc: "",
    });
    this.setState({ enable: false });
  };

  capturarEventoOrdenar = (vEventoAdd) => {
    this.setState({
      vEventoExternoAdd: vEventoAdd,
    });
  };

  existe_Tramites = () => {
    if (this.props.tramites?.length > 0) {
      return (
        <>
          <Formularios_Disponibles
            tipo="tramite"
            activado={false}
            accionesExternas={this.capturarEventoOrdenar}
            accionEditar={this.accionEditar}
            itemr={this.props.tramites}
            agregar_EliminarTramites={this.props.agregar_EliminarTramites}
            titulo_Modal="Eliminar tramite"
            descripcion_Modal={
              <>
                <p>
                  Se eliminará de forma permanente el formulario, lo que implica
                  que no podrá recuperarlo.
                  <br />
                  ¿Desea continuar?
                </p>
              </>
            }
          />
        </>
      );
    } else {
      return <NoTramites />;
    }
  };

  accionEditar = (tramite) => {
    this.props.CambiarFrameEdit("creacion_admin", tramite);
  };

  cambiarEstadoModal = (estate) => {
    this.setState({ active: estate ? "is-active" : "" });
  };

  render() {
    return (
      <div key={this.state.key} className="contenedor">
        <div className="contenedor sombra pad gridT">
          <h1 className="titleMod1">Configuración de los trámites</h1>
          <OverlayTrigger
            trigger="hover"
            key="left"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-left`}>
                <Popover.Header as="h3">Descargar datos</Popover.Header>
                <Popover.Body>
                  Se creará un Excel con todos los datos de los alumnos
                  registrados en el servicio social, este proceso podría tardar
                  un poco!.
                </Popover.Body>
              </Popover>
            }
          >
            <button
              className="btrn5"
              onClick={() => this.cambiarEstadoModal(true)}
            >
              <span class={"icon "}>
                <ion-icon name="cloud-download"></ion-icon>
              </span>
            </button>
          </OverlayTrigger>
          <Modal
            showButton={false}
            isIcono={false}
            variant="danger"
            tamaño="is-medium"
            tipo="5"
            accion_aceptar={() => this.props.CambiarFrame("descargarInfo")}
            boton_texto="Cancelar"
            isText={true}
            titulo="Descargar información"
            descripcion={
              <p>
                Este proceso se demorará dependiendo de los datos disponibles en
                la nube.
              </p>
            }
            isAceptar_tache={false}
            cambiarEstadoModal={this.cambiarEstadoModal}
            isCambiarEstado={true}
            stateModal={this.state.active}
          />
          <OverlayTrigger
            trigger="hover"
            key="left"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-left`}>
                <Popover.Header as="h3">Configuraciones extras</Popover.Header>
                <Popover.Body>
                  Entrará en modo mas configuraciones, de clic aqui.
                </Popover.Body>
              </Popover>
            }
          >
            <button
              className="btrn5"
              onClick={() => {
                this.props.CambiarFrame("config_pagina");
              }}
            >
              <span class={"icon "}>
                <ion-icon name="settings"></ion-icon>
              </span>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            key="left"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-left`}>
                <Popover.Header as="h3">Cerrar sesión</Popover.Header>
                <Popover.Body>
                  Para mayor seguridad se recomienda cerrar sesión, de clic
                  aquí.
                </Popover.Body>
              </Popover>
            }
          >
            <button
              className="btrn5"
              onClick={() => {
                Api.mCerrarSesion().then((data) => {
                  if (data["estado"] === "destruido") {
                    localStorage.removeItem("activo");
                    localStorage.setItem("activo", "0");
                    localStorage.removeItem("cookie");
                    this.props.CambiarFrame("login");
                  }
                });
              }}
            >
              <span class={"icon "}>
                <ion-icon name="exit"></ion-icon>
              </span>
            </button>
          </OverlayTrigger>
        </div>
        <div className="sombra2">
          <h2 className="titleMod">Trámites disponibles</h2>
        </div>
        <div className="sombra2">
          <div className="contenedor2">
            <button
              className="button is-success is-light pad2"
              onClick={() => this.props.CambiarFrame("creacion_admin")}
            >
              <span>Crear nuevo trámite</span>
              <span class={"icon "}>
                <ion-icon name="add-circle"></ion-icon>
              </span>
            </button>
            {this.existe_Tramites()}
          </div>
        </div>
      </div>
    );
  }
}
