/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";

export default class Acerca_de extends Component {
  state = {
    center: {
      lat: Number.parseFloat(this.props.pag_info.latitud),
      lng: Number.parseFloat(this.props.pag_info.longitud),
    },
    zoom: 18,
  };

  render() {
    return (
      <div className="contenedor">
        <div className="sombra2">
          <h1 class="titleMod">Acerca de</h1>
        </div>
        {
          //aqui empieza los 2
        }
        <div className="grid">
          <div className="sombra3">
            <h2>
              <strong class="is-size-4 ">
                Servicio Social AppWeb '
                {this.props.pag_info.abreviatura_nombre_universidad}'
              </strong>
            </h2>
            <br />
            <p
              class="jus"
              dangerouslySetInnerHTML={{
                __html: this.props.pag_info.descripcion_acercade,
              }}
            />
            <br />
            Datos de contacto:
            <br />
            <ul>
              <li>{this.props.pag_info.nombre_jefe}</li>
              <li>{this.props.pag_info.correo_electronico_contacto}</li>
            </ul>
            <br />
            <br />
            <strong>Atte: El equipo de Tecnologinc</strong>
          </div>
          <div className="sombra3">
            <h2>
              <strong class="is-size-4">Ubicación</strong>
            </h2>
            <p
              class="jus"
              dangerouslySetInnerHTML={{
                __html: this.props.pag_info.direccion_institucion,
              }}
            />
            <br />
            <p
              class="jus"
              dangerouslySetInnerHTML={{
                __html: this.props.pag_info.direccion_oficina,
              }}
            />
            <br />
            <div className="clo">
              <iframe
                src={this.props.pag_info.url_maps}
                style={{ border: 0, width: "100%", height: "100%" }}
                allowfullscreen=""
                loading="quick"
              ></iframe>
            </div>
          </div>
        </div>
        {
          //fin de las 2
        }
      </div>
    );
  }
}
