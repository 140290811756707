import React, { Component } from "react";

import * as Api from "../../utils/api";

export default class cargando extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCargado: false,
    };
  }
  async componentDidMount() {
    try {
      if (
        localStorage.getItem("activo") === "1" &&
        localStorage.getItem("cookie") !== null
      ) {
        Api.descargarDatosXLM(this.props.tramites).then((data) => {
          if (data.length > 1) {
            this.setState({ isCargado: false });
          } else {
            if (data === "1") {
              this.setState({ isCargado: true });
            }
          }
        });
      } else {
        this.setState({ isCargado: true });
      }
    } catch (error) {
      alert(error);
    }
  }
  render() {
    if (this.state.isCargado) {
      return <>{this.props.CambiarFrame("config_tramites")}</>;
    } else {
      return (
        <div className="sombra3 pad2">
          <h2 className="titleMod2">{this.props.texto}</h2>
          <div className="loader2" />
        </div>
      );
    }
  }
}
