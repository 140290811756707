/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";

export default class Header_Alumno_Pc extends Component {
  state = {
    tramite: "btrn2d",
    ayuda: "btrn2",
    acerca_de: "btrn2",
    isFormulario: false,
    isTramiteN: false,
  };

  clickBoton = (evt) => {
    switch (evt.target.name) {
      case "tramite":
        this.setState({
          tramite: "btrn2d",
          ayuda: "btrn2",
          acerca_de: "btrn2",
          isFormulario: false,
          isTramiteN: false,
        });
        this.props.cambiarPathN("Tramites");
        break;
      case "ayuda":
        this.setState({
          tramite: "btrn2",
          ayuda: "btrn2d",
          acerca_de: "btrn2",
          isFormulario: false,
          isTramiteN: false,
        });
        this.props.cambiarPathN("Ayuda");
        break;
      case "acerca_de":
        this.setState({
          tramite: "btrn2",
          ayuda: "btrn2",
          acerca_de: "btrn2d",
          isFormulario: false,
          isTramiteN: false,
        });
        this.props.cambiarPathN("Acerca de");
        break;
      default:
        this.setState({
          tramite: "btrn2",
          ayuda: "btrn2",
          acerca_de: "btrn2",
          isFormulario: false,
          isTramiteN: false,
        });
        break;
    }
  };

  isFormulario = () => {
    if (this.props.isFormulario) {
      if (!this.state.isFormulario) {
        this.setState({
          tramite: "btrn2",
          ayuda: "btrn2",
          acerca_de: "btrn2",
          isFormulario: true,
          isTramiteN: false,
        });
      }
    } else {
      if (this.props.ubicacion === "Tramites") {
        if (!this.state.isTramiteN) {
          this.setState({
            tramite: "btrn2d",
            ayuda: "btrn2",
            acerca_de: "btrn2",
            isFormulario: false,
            isTramiteN: true,
          });
        }
      }
    }
  };

  render() {
    this.isFormulario();
    return (
      <div className="contenedor sombra pad gridH">
        <img className="Imagen__Inicio" src="img/logo_uaemamex.png" />
        <h1 className="h1 titleMod1">{this.props.pag_info.nombre_app}</h1>
        <div className="cudBtn">
          <button
            name="tramite"
            className={"button " + this.state.tramite}
            onClick={this.clickBoton}
          >
            Trámites
          </button>
          <button
            name="ayuda"
            className={"button " + this.state.ayuda}
            onClick={this.clickBoton}
          >
            Ayuda
          </button>
          <button
            name="acerca_de"
            className={"button " + this.state.acerca_de}
            onClick={this.clickBoton}
          >
            Acerca de
          </button>
        </div>
      </div>
    );
  }
}
