import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { Form } from "react-bootstrap";

export default class inputEditorText extends Component {
  constructor(props) {
    super(props);
    var Draft = this.props.value;
    var exist = Draft.includes("<br/>");
    while (exist === true) {
      Draft = Draft.replace("<br/>", "<p>/</p>");
      exist = Draft.includes("<br/>");
    }
    const contentBlock = htmlToDraft(Draft);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState) => {
    var html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    var exist = html.includes("<p>/</p>");
    while (exist === true) {
      html = html.replace("<p>/</p>", "<br/>");
      exist = html.includes("<p>/</p>");
    }
    this.props.cambiarInfoFormulario({ name: this.props.name, value: html });
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <Form.Group className="margen_textEditor pad2">
        {
          //margen_textEditor > ModalForm > ModalForm.css
        }
        <div>
          <p>{this.props.titulo}</p>
          <Editor
            wrapperClassName=""
            toolbarClassName="sombra3 flygps frutiColor"
            editorClassName="sombra3"
            toolbar={{
              options: [
                "history",
                "fontSize",
                "blockType",
                "remove",
                "inline",
                "list",
                "textAlign",
                "link",
              ],
            }}
            localization={{
              locale: "es",
            }}
            onEditorStateChange={this.onEditorStateChange}
            editorState={editorState}
          />
          <Form.Text>{this.props.comentarios}</Form.Text>
        </div>
      </Form.Group>
    );
  }
}
