import React, { Component } from "react";

export default class noInfor extends Component {
  render() {
    return (
      <div className="sombra2 gridT">
        <h2 className="titleMod2">Por el momento no existe ningun trámite</h2>
        <img src="https://img.icons8.com/plumpy/100/000000/sad.png" />
      </div>
    );
  }
}
