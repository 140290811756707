import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";

import InputTextFile from "../../Componente/inputsForm/inputTextFile";

import * as Api from "../../utils/api";

export default class addAcount extends Component {
  enviar = (evt) => {
    evt.preventDefault();

    if (
      evt.target[0].value.length > 0 &&
      evt.target[1].value.length > 0 &&
      evt.target[2].value.length > 0
    ) {
      const bcrypt = require("bcryptjs");
      const rondasDeSal = 10;
      bcrypt.hash(evt.target[2].value, rondasDeSal, (err, encryted) => {
        if (err) {
        } else {
          Api.addAcount(
            evt.target[0].value.trim(),
            evt.target[1].value.trim(),
            encryted
          ).then((data) => {
            if (data === "true") {
              this.props.CambiarFrame("login");
            }
          });
        }
      });
    }
  };

  render() {
    return (
      <div className="contenedor2">
        <h1 className="sombra2 pad cen">
          <strong>Cuidao!. si no perteneces aqui no le toques cucha.</strong>
        </h1>

        <Form className="gridH" onSubmit={this.enviar}>
          <InputTextFile
            id="name"
            pregunta="Usuario"
            entrada="text"
            placeholder="Nombre de usuario"
          />
          <InputTextFile
            id="email"
            pregunta="Correo electronico"
            entrada="email"
            placeholder="Placeholder"
          />
          <InputTextFile
            id="pass"
            pregunta="Contraseña"
            entrada="password"
            placeholder="Contraseña"
          />
          <Button className="btrn4 pad2 botonA" type="submit">
            Añadir
          </Button>
        </Form>
      </div>
    );
  }
}
