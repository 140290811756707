/* eslint-disable no-fallthrough */
/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import {
  Accordion,
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";

import Acordion from "../../../Componente/showInfo/Acordion";
import InputFiles from "../../../Componente/inputsForm/inputFiles";
import InputEditorText from "../../../Componente/inputsForm/inputEditorText";
import Preguntas from "../../../Componente/showInfo/Ordenar_Opciones/Ordenar_Opciones";
import Text_modalForm from "../../../Componente/ModalForm/Text_modalForm";
import Opcs_modalForm from "../../../Componente/ModalForm/Opcs_modalForm";
import Date_modalForm from "../../../Componente/ModalForm/Date_modalForm";
import Modal from "../../../Componente/showInfo/Modals/Modal";
import FormCreate from "./FormCreateView";

import * as Api from "../../../utils/api";

export default class Creacion_Admin extends Component {
  constructor(props) {
    super(props);
    if (props.tramiteEdit != null) {
      var info_tramite = props.tramiteEdit.contenido.info_tramite;
      var info_correo_archivos =
        props.tramiteEdit.contenido.info_correo_archivos;
      var info_formularioN = props.tramiteEdit.contenido.info_formulario;

      this.state = {
        vTramites: props.tramites,
        idTramite: props.tramiteEdit.id,
        nombre_tramite: info_tramite.nombre_tramite,
        descripcionBreve_tramite: info_tramite.descripcionBreve_tramite,
        comentario_formulario: info_tramite.comentario_formulario,
        descripcion_tramite: info_tramite.descripcion_tramite,
        video_tramite: info_tramite.video_tramite,
        info_formulario: info_formularioN,
        asunto_correo: info_correo_archivos.asunto_correo,
        cuerpo_correo: info_correo_archivos.cuerpo_correo,
        archivos_seleccionados: info_correo_archivos.archivos_seleccionados,

        vEventoExternoAdd: "",
        vEventoExternoForm: "",
        contNList: 1,
        active: "",
        activeErr: "",
        infoErrModal: "",
        isEdit: true,
        preguntaEdit: null,
        preguntaEditIndex: 0,
        activeEditP: "",
        movimientosEdit: [],
        mEstadoSesion: props.mEstadoSesion,
      };
    } else {
      this.state = {
        vTramites: props.tramites,
        idTramite: "tramite_" + new Date().getTime(),
        nombre_tramite: "",
        descripcionBreve_tramite: "",
        comentario_formulario: "",
        descripcion_tramite: "",
        video_tramite: "",
        info_formulario: [
          {
            id: "correo_alumno",
            texto: "Correo electrónico",
            pregunta: "Correo electrónico:",
            comentarios: "Donde recibirá los documentos del tramite.",
            entrada: "email",
            tipo_respuesta: "0",
            respuestas: "",
            placeholder: "ejemplo@ejemplo.ej",
            obligatoria: "true",
            guardar_info: "true",
          },
          {
            id: "no_cuenta_alumno",
            texto: "Número de cuenta",
            pregunta: "Número de cuenta:",
            comentarios: "Número con el que se identifica en la universidad.",
            entrada: "number",
            tipo_respuesta: "0",
            respuestas: "",
            placeholder: "1234567",
            obligatoria: "true",
            guardar_info: "true",
          },
        ],
        asunto_correo: "",
        cuerpo_correo: "",
        archivos_seleccionados: [],

        vEventoExternoAdd: "",
        vEventoExternoForm: "",
        contNList: 1,
        active: "",
        activeErr: "",
        infoErrModal: "",
        isEdit: false,
        preguntaEdit: null,
        preguntaEditIndex: 0,
        activeEditP: "",
        mEstadoSesion: props.mEstadoSesion,
      };
    }
  }

  cambiarEstadoPEdit = (estate) => {
    this.actualizarTiempoActividad();
    this.setState({ activeEditP: estate ? "is-active" : "" });
    if (!estate) {
      this.setState({
        preguntaEdit: null,
      });
    }
  };
  cambiarEstadoModal = (estate) => {
    this.actualizarTiempoActividad();
    this.setState({ active: estate ? "is-active" : "" });
  };

  cambiarEstadoModalErr = (estate) => {
    this.actualizarTiempoActividad();
    this.setState({ activeErr: estate ? "is-active" : "" });
  };

  onClickGuardar = () => {
    this.actualizarTiempoActividad();
    if (
      this.state.nombre_tramite.length > 0 &&
      this.state.descripcionBreve_tramite.length > 8 &&
      this.state.comentario_formulario.length > 8 &&
      this.state.descripcion_tramite.length > 8 &&
      this.state.info_formulario.length > 2 &&
      this.state.asunto_correo.length > 0 &&
      this.state.cuerpo_correo.length > 8 &&
      this.state.archivos_seleccionados.length > 0
    ) {
      this.setState({ active: "is-active" });
    } else {
      var infoErrModalTmp =
        "<p>Los sigueintes campos deben ser rellenados:</p>";

      if (
        this.state.nombre_tramite.length <= 0 ||
        this.state.descripcionBreve_tramite.length <= 8 ||
        this.state.comentario_formulario.length <= 8 ||
        this.state.descripcion_tramite.length <= 8
      ) {
        infoErrModalTmp +=
          "<strong>Paso 1. Informacion del tramite:</strong> <br/> <l>";
        if (this.state.nombre_tramite.length <= 0) {
          infoErrModalTmp += "<li>Nombre del tramite</li>";
        }
        if (this.state.descripcionBreve_tramite.length <= 8) {
          infoErrModalTmp += "<li>Descripción breve del tramite</li>";
        }
        if (this.state.comentario_formulario.length <= 8) {
          infoErrModalTmp += "<li>Comentario del formulario</li>";
        }
        if (this.state.descripcion_tramite.length <= 8) {
          infoErrModalTmp += "<li>Descripción del tramite</li>";
        }
        infoErrModalTmp += "</l><br/>";
      }

      if (this.state.info_formulario.length <= 2) {
        infoErrModalTmp +=
          "<strong>Paso 2. Información del formulario:</strong><br/><l><li>El formulario debe contener 3 o más preguntas</li></l><br/>";
      }
      if (
        this.state.asunto_correo.length <= 0 ||
        this.state.cuerpo_correo <= 8 ||
        this.state.archivos_seleccionados.length <= 0
      ) {
        infoErrModalTmp +=
          "<strong>Paso 3. Cuerpo del correo y documento:</strong><br/><l>";

        if (this.state.asunto_correo.length <= 0) {
          infoErrModalTmp += "<li>Asunto del correo</li>";
        }
        if (this.state.cuerpo_correo.length <= 8) {
          infoErrModalTmp += "<li>Cuerpo del correo</li>";
        }
        if (this.state.archivos_seleccionados.length <= 0) {
          infoErrModalTmp +=
            "<li>Debe que cargar por lo menos un archivo .docx</li>";
        }
        infoErrModalTmp += "</l>";
      }

      this.setState({
        infoErrModal: infoErrModalTmp,
        activeErr: "is-active",
      });
    }
  };

  capturarEventoOrdenar = (vEventoAdd) => {
    this.actualizarTiempoActividad();
    this.setState({
      vEventoExternoAdd: vEventoAdd,
    });
  };

  capturarEventoForm = (vEventoForm) => {
    this.setState({
      vEventoExternoForm: vEventoForm,
    });
  };

  actualizarForm = (vItemsN) => {
    this.state.vEventoExternoForm(vItemsN);
  };

  accion = (vIemPregunta, isEditP) => {
    this.actualizarTiempoActividad();
    this.state.vEventoExternoAdd(
      vIemPregunta,
      isEditP,
      this.state.preguntaEditIndex
    ); //accion que llevar al Ordenar_Opciones.jsx > addOpcc()
  };

  cambiarInfoFormulario = (evt) => {
    this.actualizarTiempoActividad();
    if (evt.target) {
      this.setState({ [evt.target.name]: evt.target.value });
    } else {
      this.setState({ [evt.name]: evt.value });
    }
  };

  actualizar_archivos_seleccionados = (archivosN) => {
    this.actualizarTiempoActividad();
    this.setState({ archivos_seleccionados: archivosN });
  };

  add = (vItemsN, tipo, accion, item) => {
    this.actualizarTiempoActividad();
    //accion 0:agregar 1:Eliminar
    if (this.state.isEdit) {
      var movimientosEdit = this.state.movimientosEdit;
      switch (accion) {
        case "0":
          movimientosEdit.push({
            accion: accion.trim(),
            id: item.id.trim(),
          });
          break;
        case "1":
          movimientosEdit.push({
            accion: accion.trim(),
            id: item.trim(),
          });
          break;
        case "2":
          if (this.state.preguntaEdit.id !== item.id) {
            movimientosEdit.push({
              accion: accion.trim(),
              tmpId: this.state.preguntaEdit.id.trim(),
              id: item.id.trim(),
            });
          }
          break;

        default:
          break;
      }
      this.setState({ movimientosEdit });
    }
    switch (tipo) {
      case "pregunta":
        this.setState({
          info_formulario: vItemsN,
        });

        this.actualizarForm(vItemsN);
        break;

      default:
        break;
    }
  };

  accionEditarP = (pregunta, index) => {
    this.setState({
      preguntaEdit: pregunta,
      preguntaEditIndex: index,
      activeEditP: "is-active",
    });
  };
  accionAceptarEditarP = (pregunta) => {
    this.setState({
      preguntaEdit: pregunta,
      activeEditP: "is-active",
    });
  };

  actualizarTiempoActividad = () => {
    this.state.mEstadoSesion(false, 4);
  };

  modalEdit = (preguntaEdit) => {
    if (preguntaEdit != null) {
      switch (preguntaEdit.tipo_respuesta) {
        case "0":
          return (
            <Text_modalForm
              showButton={false}
              isCambiarEstado={true}
              stateModal={this.state.activeEditP}
              cambiarEstadoModal={this.cambiarEstadoPEdit}
              value={this.state.preguntaEdit}
              titulo="Agregar TextFile"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="is-rounded"
              tamaño="is-small"
              direccion="column"
              boton_texto="TextField"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
              actualizarTiempoActividad={this.actualizarTiempoActividad}
            />
          );
        case "1":
          return (
            <Text_modalForm
              showButton={false}
              isCambiarEstado={true}
              stateModal={this.state.activeEditP}
              cambiarEstadoModal={this.cambiarEstadoPEdit}
              value={this.state.preguntaEdit}
              titulo="Agregar TextArea"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="is-rounded"
              tamaño="is-small"
              direccion="column"
              boton_texto="TextArea"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
            />
          );
        case "2":
          return (
            <Opcs_modalForm
              showButton={false}
              isCambiarEstado={true}
              stateModal={this.state.activeEditP}
              cambiarEstadoModal={this.cambiarEstadoPEdit}
              value={this.state.preguntaEdit}
              titulo="Agregar Radio"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="is-rounded"
              tamaño="is-small"
              direccion="column"
              boton_texto="Radio"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
            />
          );
        case "3":
          return (
            <Opcs_modalForm
              showButton={false}
              isCambiarEstado={true}
              stateModal={this.state.activeEditP}
              cambiarEstadoModal={this.cambiarEstadoPEdit}
              value={this.state.preguntaEdit}
              titulo="Agregar ComboBox"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="is-rounded"
              tamaño="is-small"
              direccion="column"
              boton_texto="ComboBox"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
            />
          );
        case "4":
          return (
            <Date_modalForm
              showButton={false}
              isCambiarEstado={true}
              stateModal={this.state.activeEditP}
              cambiarEstadoModal={this.cambiarEstadoPEdit}
              value={this.state.preguntaEdit}
              titulo="Agregar Date"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="is-rounded"
              tamaño="is-small"
              direccion="column"
              boton_texto="Date"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
            />
          );
        default:
      }
    } else {
      return <></>;
    }
  };

  informacion_tramite = () => {
    return (
      <>
        <FloatingLabel
          controlId="nombre_tramite"
          label="Nombre del trámite"
          className="mb-3"
        >
          <Form.Control
            autocomplete="off"
            type="text"
            placeholder="Nombre del trámite"
            name="nombre_tramite"
            value={this.state.nombre_tramite}
            onChange={this.cambiarInfoFormulario}
            onFocus={this.actualizarTiempoActividad}
          />
        </FloatingLabel>
        <InputEditorText
          titulo="Descripción breve del trámite"
          name="descripcionBreve_tramite"
          value={this.state.descripcionBreve_tramite}
          cambiarInfoFormulario={this.cambiarInfoFormulario}
        />
        <InputEditorText
          titulo="Comentario del formulario"
          name="comentario_formulario"
          value={this.state.comentario_formulario}
          cambiarInfoFormulario={this.cambiarInfoFormulario}
        />
        <InputEditorText
          titulo="Descripción del trámite"
          name="descripcion_tramite"
          value={this.state.descripcion_tramite}
          cambiarInfoFormulario={this.cambiarInfoFormulario}
        />
        <FloatingLabel
          controlId="video_tramite"
          label="Video del trámite (Solo si es necesario)"
          className="mb-3"
        >
          <Form.Control
            autocomplete="off"
            type="text"
            placeholder="Vídeo del trámite (Solo si es necesario)"
            name="video_tramite"
            value={this.state.video_tramite}
            onChange={this.cambiarInfoFormulario}
          />
        </FloatingLabel>
      </>
    );
  };
  informacion_formulario = () => {
    return (
      <div className="">
        <div className="sombra2">
          <div className="gridBotones">
            <Text_modalForm
              showButton={true}
              isCambiarEstado={false}
              value={null}
              titulo="Agregar TextFile"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="btrn6"
              direccion="column"
              boton_texto="TextField"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
              actualizarTiempoActividad={this.actualizarTiempoActividad}
            />
            <Text_modalForm
              showButton={true}
              isCambiarEstado={false}
              value={null}
              titulo="Agregar TextArea"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="btrn6"
              direccion="column"
              boton_texto="TextArea"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
              actualizarTiempoActividad={this.actualizarTiempoActividad}
            />
            <Opcs_modalForm
              showButton={true}
              isCambiarEstado={false}
              value={null}
              titulo="Agregar Radio"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="btrn6"
              direccion="column"
              boton_texto="Radio"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
              actualizarTiempoActividad={this.actualizarTiempoActividad}
            />
            <Opcs_modalForm
              showButton={true}
              isCambiarEstado={false}
              value={null}
              titulo="Agregar ComboBox"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="btrn6"
              direccion="column"
              boton_texto="ComboBox"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
              actualizarTiempoActividad={this.actualizarTiempoActividad}
            />
            <Date_modalForm
              showButton={true}
              isCambiarEstado={false}
              value={null}
              titulo="Agregar Date"
              variant="outline-warning"
              icon_ion="duplicate-outline"
              estilo_className="btrn6"
              direccion="column"
              boton_texto="Date"
              accion_aceptar={this.accion}
              items={this.state.info_formulario}
              actualizarTiempoActividad={this.actualizarTiempoActividad}
            />
          </div>
          {this.modalEdit(this.state.preguntaEdit)}
        </div>

        <Row>
          <Col sm={3}>
            <Preguntas
              itemr={this.state.info_formulario}
              style="boxOrdenarPreguntas"
              activado={false}
              tipo="pregunta"
              agregar_EliminarTramites={this.add}
              accionesExternas={this.capturarEventoOrdenar}
              accionEditar={this.accionEditarP}
              titulo_Modal="Eliminar pregunta"
              descripcion_Modal={
                <>
                  <p>
                    Se eliminará de forma permanente la pregunta, lo que implica
                    que no podra recuperarlo.
                    <br />
                    ¿Desea continuar?
                  </p>
                </>
              }
            />
          </Col>
          <Col md>
            <FormCreate
              tipo="3"
              titlo_Form={this.state.nombre_tramite}
              descripcion={this.state.comentario_formulario}
              accionExterna={this.capturarEventoForm}
              preguntas={this.state.info_formulario}
            />
          </Col>
        </Row>
      </div>
    );
  };
  cuerpo_correo = () => {
    return (
      <Form.Group>
        <FloatingLabel
          controlId="asunto_correo"
          label="Asunto del correo"
          className="mb-3"
        >
          <Form.Control
            autocomplete="off"
            type="text"
            placeholder="Asunto del correo"
            name="asunto_correo"
            value={this.state.asunto_correo}
            onChange={this.cambiarInfoFormulario}
            onFocus={this.actualizarTiempoActividad}
          />
        </FloatingLabel>
        <InputEditorText
          titulo="Cuerpo del correo"
          name="cuerpo_correo"
          value={this.state.cuerpo_correo}
          cambiarInfoFormulario={this.cambiarInfoFormulario}
        />
        <InputFiles
          actualizar_archivos_seleccionados={
            this.actualizar_archivos_seleccionados
          }
          archivos_seleccionados={this.state.archivos_seleccionados}
          onClick={this.actualizarTiempoActividad}
        />
      </Form.Group>
    );
  };

  crearListArchivos = () => {
    var archivos = [];
    var cont = 1;
    for (const archivo in this.state.archivos_seleccionados) {
      if (
        Object.hasOwnProperty.call(this.state.archivos_seleccionados, archivo)
      ) {
        var arch = {
          nombre:
            this.state.archivos_seleccionados[cont - 1].name == null
              ? this.state.archivos_seleccionados[cont - 1].nombre
              : this.state.archivos_seleccionados[cont - 1].name,
          tamanio:
            this.state.archivos_seleccionados[cont - 1].size == null
              ? this.state.archivos_seleccionados[cont - 1].tamanio
              : this.state.archivos_seleccionados[cont - 1].size,
        };
        archivos.push(arch);
        cont++;
      }
    }
    return archivos;
  };

  guardar_Form = () => {
    var info_correo_archivosN = {
      asunto_correo: this.state.asunto_correo,
      cuerpo_correo: this.state.cuerpo_correo,
      archivos_seleccionados: this.crearListArchivos(),
    };

    var info_tramiteN = {
      nombre_tramite: this.state.nombre_tramite,
      descripcionBreve_tramite: this.state.descripcionBreve_tramite,
      comentario_formulario: this.state.comentario_formulario,
      descripcion_tramite: this.state.descripcion_tramite,
      video_tramite: this.state.video_tramite,
    };

    var contenidoN = {
      info_correo_archivos: info_correo_archivosN,
      info_tramite: info_tramiteN,
      info_formulario: this.state.info_formulario,
    };

    var tramite = {
      id: this.state.idTramite,
      contenido: contenidoN,
    };

    var tramites = this.state.vTramites;

    if (!this.state.isEdit) {
      tramites.push(tramite);

      this.props.agregar_EliminarTramites(tramites, "", "", "", "");

      Api.subirArchivos(
        this.state.archivos_seleccionados,
        tramite,
        this.state.info_formulario,
        {
          isModificadoNombre: false,
          nombreOld: "",
        }
      ).then((data) => {
        switch (data["estado"]) {
          case "no sesion":
            break;

          default:
            break;
        }
      });
      Api.crearDB(tramite).then((data) => {});
    } else {
      tramites[
        tramites.findIndex(
          (x) =>
            x.contenido.info_tramite.nombre_tramite ===
            this.props.tramiteEdit.contenido.info_tramite.nombre_tramite
        )
      ] = tramite;

      this.props.agregar_EliminarTramites(tramites, "", "", "", "");

      Api.modificarDB(
        this.state.idTramite,
        this.state.movimientosEdit,
        tramite
      ).then((data) => {});
      Api.subirArchivos(
        this.state.archivos_seleccionados,
        tramite,
        this.state.info_formulario
      ).then((data) => {});
    }
    this.props.CambiarFrame("config_tramites");
  };

  descModalFinalizar = () => {
    if (this.state.isEdit) {
      return "La modificación del formulario ha finalizado, de clic en [Aceptar]";
    } else {
      return "La creación del formulario ha finalizado, de clic en [Aceptar]";
    }
  };
  titulloMod = () => {
    if (this.state.isEdit) {
      return "Modificación del formulario";
    } else {
      return "Creación de formularios";
    }
  };
  textBoton = () => {
    if (this.state.isEdit) {
      return "Modificar trámite";
    } else {
      return "Guardar trámite";
    }
  };

  render() {
    return (
      <div className="contenedor">
        <div className="sombra pad">
          <h1 className="titleMod1">{this.titulloMod()}</h1>
        </div>
        <div className="sombra2">
          <Accordion defaultActiveKey="0">
            <Acordion
              activar="0"
              Titulo="Paso 1. Información del trámite"
              contenido={this.informacion_tramite()}
            />
            <Acordion
              activar="1"
              Titulo="Paso 2. Información del formulario"
              contenido={this.informacion_formulario()}
            />
            <Acordion
              activar="2"
              Titulo="Paso 3. Cuerpo del correo y documento"
              contenido={this.cuerpo_correo()}
            />
          </Accordion>
          <br />
        </div>
        <footer className="cenItem fomodal">
          <Button variant="" onClick={this.onClickGuardar} className={"btrn3"}>
            {this.textBoton()}
          </Button>
          <Modal
            showButton={false}
            isIcono={false}
            variant="success"
            tamaño="is-medium"
            tipo="3"
            isAceptar_tache={true}
            accion_aceptar={() => this.guardar_Form()}
            boton_texto="Guardar trámite"
            isText={true}
            titulo="Finalizar"
            descripcion={this.descModalFinalizar()}
            cambiarEstadoModal={this.cambiarEstadoModal}
            isCambiarEstado={true}
            stateModal={this.state.active}
          />
          <Modal
            showButton={false}
            isIcono={false}
            variant="success"
            tamaño="is-medium"
            tipo="3"
            isAceptar_tache={true}
            accion_aceptar={() => {}}
            boton_texto=""
            isText={true}
            titulo="Error al intentar guardar"
            descripcion={
              <div
                dangerouslySetInnerHTML={{ __html: this.state.infoErrModal }}
              />
            }
            cambiarEstadoModal={this.cambiarEstadoModalErr}
            isCambiarEstado={true}
            stateModal={this.state.activeErr}
          />
          <Modal
            estilo_className="btrn3c"
            showButton={true}
            isIcono={false}
            variant=""
            tamaño="is-medium"
            tipo="2"
            accion_aceptar={() => this.props.CambiarFrame("config_tramites")}
            boton_texto="Cancelar"
            isText={true}
            titulo="Salir sin guardar"
            descripcion={
              <p>¡ADVERTENCIA!, si continúa no se guardará ningún cambio.</p>
            }
            cambiarEstadoModal={() => {}}
            isCambiarEstado={false}
          />
        </footer>
      </div>
    );
  }
}
