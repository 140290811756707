import React, { Component } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FloatingLabel, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: "100%",
});

const cambiadoId = false;

export default class opcForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opc: "",
      items: [],
      isEliminar: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.respuestas,
      result.source.index,
      result.destination.index
    );
    this.props.setRespuestas(items);
  }

  eliminar = (indexS) => {
    if (this.state.isEliminar) {
      const tmpItems = [];
      var cont = 1;
      this.props.respuestas.map((item, index) => {
        if (index !== indexS) {
          const itemN = {
            id: this.props.items_id + "_" + cont,
            opc: item.opc,
          };
          tmpItems.push(itemN);
          cont++;
        }
      });
      this.props.setRespuestas(tmpItems);
    }
  };

  addOpc = (evt) => {
    if (evt.which === 13) {
      if (this.state.opc.trim().length > 0) {
        const itemNuevo = {
          id: this.props.items_id + "_" + (this.props.respuestas.length + 1),
          opc: this.state.opc,
        };

        var items = this.props.respuestas;
        items.push(itemNuevo);

        this.setState({
          opc: "",
        });
        this.props.setRespuestas(items);
      }
      evt.preventDefault();
    }
  };

  cambiando = (evt) => {
    this.setState({ opc: evt.target.value });
  };

  enviar = (evt) => {
    evt.preventDefault();
  };

  checarId = (id) => {
    if (!this.cambiadoId) {
      this.props.activar_opc_ids_cambiado(true);
      this.cambiadoId = true;
      var items = [];

      this.props.respuestas.map((item, index) => {
        const itemN = {
          id: id + "_" + (index + 1),
          opc: item.opc,
        };
        items.push(itemN);
      });

      this.props.setRespuestas(items);
    }
  };

  render() {
    this.cambiadoId = this.props.opc_ids_cambiado;
    this.checarId(this.props.items_id);
    return (
      <div className="box">
        <FloatingLabel
          controlId="id_pregunta"
          label="Ingrese opción"
          className="mb-3"
        >
          <Form.Control
            type="text"
            onKeyDown={this.addOpc}
            onChange={this.cambiando}
            value={this.state.opc}
            name="opc"
            placeholder="id"
          />
        </FloatingLabel>
        <div className="box">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {this.props.respuestas.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="box level"
                        >
                          <div className="level-left">
                            <div className="level-item">
                              <p>{index + 1 + ") " + item.opc}</p>
                            </div>
                          </div>
                          <div className="level-right">
                            <div className="level-item">
                              <button
                                className="button"
                                onClick={() => {
                                  this.eliminar(index);
                                }}
                                onMouseUp={() =>
                                  this.setState({ isEliminar: true })
                                }
                                onMouseOut={() =>
                                  this.setState({ isEliminar: false })
                                }
                              >
                                <ion-icon name="trash"></ion-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}
