/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Ordenar_Opciones from "./Opciones";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: "100%",
});

export default class opcForm extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    items: [],
    activado: false,
    activ: false,
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    var items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });

    this.props.agregar_EliminarTramites(items, this.props.tipo, "-1", ":D");

    if (this.state.activado === true) {
      this.state.accion_Ordenado();
    }
  }

  eliminar = (IdEliminar) => {
    const tmpItems = [];
    var delTramite = this.props.tipo === "tramite" ? "" : IdEliminar;
    this.state.items.map((item) => {
      if (item.id !== IdEliminar) {
        tmpItems.push(item);
      } else {
        if (this.props.tipo === "tramite") {
          delTramite = item;
        }
      }
    });
    this.setState({
      items: tmpItems,
    });
    this.props.agregar_EliminarTramites(
      tmpItems,
      this.props.tipo,
      "1",
      delTramite
    );
  };

  addOpcc = (vItemN, isEditP, indexEditP) => {
    if (isEditP) {
      var items = [];
      this.state.items.map((item, index) => {
        if (indexEditP === index) {
          items.push(vItemN);
        } else {
          items.push(item);
        }
      });
      this.setState({
        items,
      });
      this.props.agregar_EliminarTramites(items, this.props.tipo, "2", vItemN);
    } else {
      switch (this.props.tipo) {
        case "pregunta":
          var items = this.state.items;
          items.push(vItemN);

          this.setState({
            items,
          });
          this.props.agregar_EliminarTramites(
            items,
            this.props.tipo,
            "0",
            vItemN
          );

          break;

        default:
          break;
      }
    }
  };

  textoItem = (index, item) => {
    switch (this.props.tipo) {
      case "tramite":
        return index + 1 + ") " + item.contenido.info_tramite.nombre_tramite;
      case "pregunta":
        return index + 1 + ") ${" + item.id + "} - " + item.pregunta;
      default:
        break;
    }
  };

  accionExterna(add) {
    this.props.accionesExternas(add);
  }

  async componentDidMount() {
    this.setState({ activado: this.props.activado });
    this.accionExterna(this.addOpcc);
    this.setState({ activ: true });
    if (this.props.tipo === "tramite") {
      this.setState({ items: this.props.itemr });
    } else {
      this.setState({ items: this.props.itemr });
    }
  }

  render() {
    return (
      <>
        <div className={"sombra3 " + this.props.style}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="sombra3"
                        >
                          <Ordenar_Opciones
                            nombre_item={this.textoItem(index, item)}
                            id_item={item.id}
                            Accion_Botton_Editar={() =>
                              this.props.accionEditar(item, index)
                            }
                            eliminarAccion={this.eliminar}
                            titulo_Modal={this.props.titulo_Modal}
                            descripcion_Modal={this.props.descripcion_Modal}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    );
  }
}
