import React, { Component } from "react";

import { elastic as Menu } from "react-burger-menu";

export default class burger extends Component {
  state = {
    tramite: "btrn2d",
    ayuda: "btrn2",
    acerca_de: "btrn2",
    openBurger: false,
    isFormulario: false,
    isTramiteN: false,
  };

  clickBoton = (evt) => {
    this.setState({ openBurger: false });
    switch (evt.target.name) {
      case "tramite":
        this.setState({
          tramite: "btrn2d",
          ayuda: "btrn2",
          acerca_de: "btrn2",
        });
        this.props.cambiarPathN("Tramites");
        break;
      case "ayuda":
        this.setState({
          tramite: "btrn2",
          ayuda: "btrn2d",
          acerca_de: "btrn2",
        });
        this.props.cambiarPathN("Ayuda");
        break;
      case "acerca_de":
        this.setState({
          tramite: "btrn2",
          ayuda: "btrn2",
          acerca_de: "btrn2d",
        });
        this.props.cambiarPathN("Acerca de");
        break;
      default:
        break;
    }
  };

  isMenuOpen = (state) => {
    this.setState({ openBurger: state.isOpen });
    return state.isOpen;
  };

  header = () => (
    <img src="img/logo_uaemamex111.png" width="500px" className="sombraCell" />
  );

  burger = () => {
    if (this.props.visibleBurger) {
      return (
        <Menu
          right
          onStateChange={this.isMenuOpen}
          isOpen={this.state.openBurger}
        >
          {this.header()}
          {
            //this.botonFormulario()
          }
          <a
            name="tramite"
            className={"button " + this.state.tramite}
            onClick={this.clickBoton}
          >
            Trámites
          </a>
          <a
            name="ayuda"
            className={"button " + this.state.ayuda}
            onClick={this.clickBoton}
          >
            Ayuda
          </a>
          <a
            name="acerca_de"
            className={"button " + this.state.acerca_de}
            onClick={this.clickBoton}
          >
            Acerca de
          </a>
        </Menu>
      );
    }
  };

  botonFormulario = () => {
    if (this.state.isFormulario) {
      return (
        <a name="tramite" className={"btrn2d"}>
          Formulario
        </a>
      );
    }
  };

  isFormulario = () => {
    if (this.props.isFormulario) {
      if (!this.state.isFormulario) {
        this.setState({
          tramite: "btrn2",
          ayuda: "btrn2",
          acerca_de: "btrn2",
          isFormulario: true,
          isTramiteN: false,
        });
      }
    } else {
      if (this.props.ubicacion == "Tramites") {
        if (!this.state.isTramiteN) {
          this.setState({
            tramite: "btrn2d",
            ayuda: "btrn2",
            acerca_de: "btrn2",
            isFormulario: false,
            isTramiteN: true,
          });
        }
      }
    }
  };

  render() {
    this.isFormulario();
    return <>{this.burger()}</>;
  }
}
