import React, { Component } from "react";
import { Accordion } from "react-bootstrap";

export default class Acordion extends Component {
  render() {
    return (
      
        <Accordion.Item eventKey={this.props.activar}>
          <Accordion.Header>{this.props.Titulo}</Accordion.Header>
          <Accordion.Body>{this.props.contenido}</Accordion.Body>
        </Accordion.Item>
    );
  }
}
