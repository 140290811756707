import React, { Component } from "react";
import { Helmet } from "react-helmet";

import * as Api from "../../utils/api";

import Cargando from "../../Componente/cargando_info/cargando2";

export default class LogInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
      contraseña: "",
      isErrorCredenciales: false,
      isCargando: "",
    };
  }

  inputText = {
    width: "calc(100% - 40px)",
    minHeight: "35px",
    marginBottom: "20px",
    border: "none",
    background: "rgba(255, 255, 255, 0.11)",
    outline: "none",
    paddingLeft: "10px",
    color: "white",
    fontSize: "20px",
    animation: "leftIn .5s linear forwards",
    animationDelay: "calc(0.08s * var(0))",
    transform: "translateX(-100%)",
    opacity: "0",
  };

  inputPass = {
    width: "calc(100% - 40px)",
    minHeight: "35px",
    marginBottom: "20px",
    border: "none",
    background: "rgba(255, 255, 255, 0.11)",
    outline: "none",
    paddingLeft: "10px",
    color: "white",
    fontSize: "20px",
    animation: "leftIn .5s linear forwards",
    animationDelay: "calc(0.08s * var(1))",
    transform: "translateX(-100%)",
    opacity: "0",
  };

  enviar = (evt) => {
    console.log("g");
    evt.preventDefault();
    this.setState({ vTipoError: "", isCargando: "is-active" });

    Api.login(this.state.usuario).then((data) => {
      console.log(data);
      switch (data["valor"]) {
        case "activando":
          this.setState({ vTipoError: "activando", isCargando: "" });
          break;
        case "bloqueado":
          this.setState({ vTipoError: "bloqueado", isCargando: "" });
          break;
        case "noExiste":
          this.setState({ vTipoError: "noExiste", isCargando: "" });
          break;
        default:
          bcrypt.compare(
            this.state.contraseña,
            data["valor"],
            (err, coinciden) => {
              if (err) {
              } else {
                if (coinciden) {
                  //las credenciales coinciden
                  localStorage.setItem("cookie", data["cookie"]);
                  Api.mActivarVerificador();
                  localStorage.setItem("activo", "1");
                  this.setState({ isCargando: "" });
                  this.props.CambiarFrame("config_tramites", true);
                } else {
                  Api.mCerrarSesion().then((data) => {
                    if (data["estado"] === "destruido") {
                      localStorage.removeItem("activo");
                      localStorage.setItem("activo", "0");
                      localStorage.removeItem("cookie");
                    }
                  });
                  this.setState({
                    vTipoError: "noCoinciden",
                    isCargando: "",
                  });
                }
              }
            }
          );
      }
    });

    const bcrypt = require("bcryptjs");
    const rondasDeSal = 10;
    bcrypt.hash(this.state.contraseña, rondasDeSal, (err, encryted) => {});
  };

  verificarInfo = (evt) => {
    if (evt.altKey) {
      switch (evt.key) {
        case "!":
          this.props.CambiarFrame("addAcount");
          break;
        default:
          break;
      }
    }
  };

  actualizarInfo = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  mManejorErrores = () => {
    switch (this.state.vTipoError) {
      case "noExiste":
      case "noCoinciden":
        return (
          <p className="errorCredenciales">
            Error al iniciar sesión.
            <br />
            Intente nuevamente.
          </p>
        );
        break;
      case "bloqueado":
        return (
          <p className="errorCredenciales">
            Error al iniciar sesión.
            <br />
            Ya existe otra sesión iniciada.
          </p>
        );
        break;

      default:
        break;
    }
  };

  render() {
    //<a onClick={() => alert("Recuperanding")}>Olvidé mi contraseña</a>
    return (
      <body id="background">
        <Helmet>
          <script src="https://code.jquery.com/jquery-3.6.0.min.js" />
          <script src="js/Particles.js"></script>
          <script src="js/config.js"></script>
          <script src="js/start.js"></script>
        </Helmet>
        <div id="background-formulario">
          <form id="form" onSubmit={this.enviar}>
            <br />
            <h3>Iniciar sesión</h3>
            <br />
            <input
              name="usuario"
              type="text"
              class="text"
              style={this.inputText}
              placeholder="Nombre de usuario"
              required="true"
              value={this.state.usuario}
              onChange={this.actualizarInfo}
              onKeyDown={this.verificarInfo}
            />
            <input
              name="contraseña"
              type="password"
              style={this.inputPass}
              className="text"
              placeholder="Contraseña"
              required="true"
              value={this.state.contraseña}
              onChange={this.actualizarInfo}
            />
            {this.mManejorErrores()}
            <button class="boton" type="submit">
              Entrar
            </button>

            <div className="creditos">Tecnologinc</div>
          </form>
        </div>
        <div key={Date.now()}>
          <Cargando isCargando={this.state.isCargando} />
        </div>
      </body>
    );
  }
}
