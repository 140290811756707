/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";

import Header_Alumno_Pc from "../Componentes/Header_Alumno_Pc";
import Header_Alumno_Cel from "../Componentes/Header_Alumno_Cel";
import Tramites from "../Componentes/Tramites/Tramites";
import Ayuda from "../Componentes/Ayuda/Ayuda";
import Acerca_de from "../Componentes/Acerca_de/Acerca_de";
import Footer_Alumno from "../Componentes/Footer_Alumno";
import FormCreate from "../../../Componente/FormsCreate/FormCreate";

import * as Api from "../../../utils/api";

import { BrowserView, MobileView } from "react-device-detect";

export default class Interfaz_Alumno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vPath: "Tramites",
      pag_info: {},
      tramites: [],
      tramite: [],
      numero_cuenta: "",
      isModalActive: false,
      isFormulario: false,
      tamAltNav: "",
      Width: window.innerWidth,
      Height: window.innerHeight,
    };
  }

  async componentDidMount() {
    try {
      Api.readJSONAlumno("2").then((data) => {
        this.setState({ tramites: data });
      });
      Api.readJSONAlumno("1").then((data) => {
        this.setState({ pag_info: data });
      });
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    } catch (error) {}
  }

  resize() {
    this.setState({
      Width: window.innerWidth,
      Height: window.innerHeight,
    });
  }

  cambiarPath = (vPathNew) => {
    switch (vPathNew) {
      case "Tramites":
        this.setState({ isFormulario: false });
        break;
      case "Ayuda":
        this.setState({ isFormulario: false });
        break;
      case "Acerca de":
        this.setState({ isFormulario: false });
        break;
      default:
    }
    this.setState({ vPath: vPathNew });
  };
  cambiarPathF = (vPathNew, tramiteN) => {
    switch (vPathNew) {
      case "Formulario":
        this.setState({ isFormulario: true });
        break;
      default:
    }
    this.setState({ vPath: vPathNew, tramite: tramiteN });
  };
  cambiarNoCuenta = (numero_cuentaN) => {
    this.setState({ numero_cuenta: numero_cuentaN });
  };
  cambiarEstadoModal = (isActive) => {
    this.setState({ isModalActive: isActive });
  };

  mostrarInformacionBody = () => {
    switch (this.state.vPath) {
      case "Tramites":
        return (
          <Tramites
            tramites={this.state.tramites}
            cambiarPathF={this.cambiarPathF}
            cambiarNoCuenta={this.cambiarNoCuenta}
            cambiarEstadoModal={this.cambiarEstadoModal}
            tamAltNav={this.state.tamAltNav}
          />
        );
      case "Ayuda":
        return (
          <Ayuda
            tramites={this.state.tramites}
            tamAltNav={this.state.tamAltNav}
          />
        );
      case "Acerca de":
        return (
          <Acerca_de
            pag_info={this.state.pag_info}
            tamAltNav={this.state.tamAltNav}
          />
        );
      case "Formulario":
        return (
          <FormCreate
            tramite={this.state.tramite}
            cambiarPath={this.cambiarPath}
            numero_cuenta={this.state.numero_cuenta}
            tamAltNav={this.state.tamAltNav}
          />
        );
      default:
    }
  };

  tipo_Header = () => {
    if (this.state.Width >= 768) {
      return (
        <Header_Alumno_Pc
          cambiarPathN={this.cambiarPath}
          isFormulario={this.state.isFormulario}
          ubicacion={this.state.vPath}
          pag_info={this.state.pag_info}
        />
      );
    } else {
      return (
        <Header_Alumno_Cel
          cambiarPathN={this.cambiarPath}
          visibleBurger={!this.state.isModalActive}
          isFormulario={this.state.isFormulario}
          ubicacion={this.state.vPath}
          pag_info={this.state.pag_info}
        />
      );
    }
  };

  render() {
    /*
    <BrowserView>{this.tipo_Header()}</BrowserView>
        <MobileView>
          <Header_Alumno_Cel
            cambiarPathN={this.cambiarPath}
            visibleBurger={!this.state.isModalActive}
            isFormulario={this.state.isFormulario}
            ubicacion={this.state.vPath}
            pag_info={this.state.pag_info}
          />
        </MobileView>
        */

    return (
      <>
        {this.tipo_Header()}

        <div className="bot">{this.mostrarInformacionBody()}</div>
        <Footer_Alumno pag_info={this.state.pag_info} />
      </>
    );
  }
}
