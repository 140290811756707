/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";

import { Form } from "react-bootstrap";

import InputTextFile from "../inputsForm/inputTextFile";
import InputTextArea from "../inputsForm/inputTextArea";
import InputRadio from "../inputsForm/inputRadio";
import InputComboBox from "../inputsForm/inputComboBox";
import InputDate from "../inputsForm/inputDate";
import Modal from "../showInfo/Modals/Modal";
import Cargando from "../cargando_info/cargando2";

import * as Api from "../../utils/api";

export default class FormCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
      infoDBTramite: null,
      isCargando: "is-active",
    };
  }

  async componentDidMount() {
    Api.searchAlumno(this.props.numero_cuenta, this.props.tramite.id).then(
      (data) => {
        if (data != "-1" && data !== "null") {
          this.setState({ infoDBTramite: data, isCargando: "" });
        } else {
          this.setState({ isCargando: "" });
        }
      }
    );
  }

  Active = () => {
    this.setState({ active: "is-active" });
  };

  Desactive = () => {
    this.setState({ active: "" });
    this.props.cambiarPathF("Tramites");
  };

  cambiarInfo = (evt) => {
    this.setState({ correo_alumno: evt.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    Api.enviarForm(
      event.target,
      this.props.tramite.id,
      this.props.tramite.contenido.info_tramite.nombre_tramite
    ).then((data) => {});

    Api.infoDB(
      event.target,
      this.props.tramite.id,
      this.props.tramite.contenido.info_tramite.nombre_tramite
    ).then((data) => {});
    this.Active();
  };

  tipoPregunta(vPregunta) {
    /*
      Tipo de respuesta que hay:
      0: Pregunta abierta con campo chico pero puede anotar mucho.
        entrada: cualquiera (textField)
      1: Pregunta abierta pero con un textArea
      2: Pregunta cerrada: radio
      3: Pregunta cerrada: ComboBox
      4: Fechas
      */
    switch (vPregunta.tipo_respuesta) {
      case "0":
        return (
          <InputTextFile
            id={vPregunta.id}
            pregunta={vPregunta.pregunta}
            entrada={vPregunta.entrada}
            placeholder={vPregunta.placeholder}
            comentarios={vPregunta.comentarios}
            valueNoCuenta={this.props.numero_cuenta}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );
        break;
      case "1":
        return (
          <InputTextArea
            id={vPregunta.id}
            pregunta={vPregunta.pregunta}
            entrada={vPregunta.entrada}
            placeholder={vPregunta.placeholder}
            comentarios={vPregunta.comentarios}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );
        break;
      case "2":
        return (
          <InputRadio
            id={vPregunta.id}
            id_compartido={vPregunta.id_compartido}
            pregunta={vPregunta.pregunta}
            respuestas={vPregunta.respuestas}
            comentarios={vPregunta.comentarios}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );
        break;
      case "3":
        return (
          <InputComboBox
            id={vPregunta.id}
            id_compartido={vPregunta.id_compartido}
            pregunta={vPregunta.pregunta}
            respuestas={vPregunta.respuestas}
            comentarios={vPregunta.comentarios}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );
        break;

      case "4":
        return (
          <InputDate
            id={vPregunta.id}
            pregunta={vPregunta.pregunta}
            comentarios={vPregunta.comentarios}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );
        break;

      default:
    }
  }

  modalForm = () => (
    <Modal
      showButton={false}
      isIcono={true}
      icon_ion="help-outline"
      variant="outline-warning"
      estilo_className="button is-rounded"
      tamaño="is-small"
      tipo="1"
      isText={false}
      titulo={<>Formulario finalizado!</>}
      descripcion={
        <p>
          Verifica tu correo electrónico{" "}
          <img src="https://img.icons8.com/color/30/000000/gmail--v1.png" />{" "}
          proporcionado anteriormente, en breve te llegará tu información.
        </p>
      }
      stateModal={this.state.active}
      accion_aceptar={() => this.props.cambiarPath("Tramites")}
    />
  );

  render() {
    if (this.state.isCargando === "is-active") {
      return (
        <div key={Date.now()}>
          <Cargando isCargando={this.state.isCargando} />
        </div>
      );
    } else {
      return (
        <Form
          className="sombra2 contenedor"
          onSubmit={this.handleSubmit}
          method="post"
        >
          <div className="contenedor2">
            <Form.Group className="cen">
              <h1 className="titleMod">
                {this.props.tramite.contenido.info_tramite.nombre_tramite}
              </h1>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    this.props.tramite.contenido.info_tramite
                      .comentario_formulario,
                }}
              />
              <>
                <br />
                <div className="styleLinea"></div>
                <br />
              </>
            </Form.Group>

            {this.props.tramite.contenido.info_formulario.map((vPregunta) => (
              <>{this.tipoPregunta(vPregunta)}</>
            ))}

            <div className="cen">
              <button className="btrn4">Enviar</button>
            </div>
            {this.modalForm()}
          </div>
        </Form>
      );
    }
  }
}
