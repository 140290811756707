import React, { Component } from "react";

import { Form } from "react-bootstrap";

import "../../../Componente/showInfo/showinfo.css";

import InputTextFile from "../../../Componente/inputsForm/inputTextFile";
import InputTextArea from "../../../Componente/inputsForm/inputTextArea";
import InputRadio from "../../../Componente/inputsForm/inputRadio";
import InputComboBox from "../../../Componente/inputsForm/inputComboBox";
import InputDate from "../../../Componente/inputsForm/inputDate";

export default class FormCreate extends Component {
  state = {
    form: [{}],
    infoDBTramite: null,
  };

  vTipoForm = "";

  async componentDidMount() {
    this.setState({ form: this.props.preguntas });
    this.props.accionExterna(this.actualizarForm);
  }

  actualizarForm = (vPreguntas) => {
    this.setState({ form: vPreguntas });
  };

  enviar = (evt) => {
    evt.preventDefault();
  };

  tipoPregunta(vPregunta) {
    /*
      Tipo de respuesta que hay:
      0: Pregunta abierta con campo chico pero puede anotar mucho.
        entrada: cualquiera (textField)
      1: Pregunta abierta pero con un textArea
      2: Pregunta cerrada: radio
      3: Pregunta cerrada: ComboBox
      4: Fechas
      */
    switch (vPregunta.tipo_respuesta) {
      case "0":
        return (
          <InputTextFile
            id={vPregunta.id}
            pregunta={vPregunta.pregunta}
            entrada={vPregunta.entrada}
            placeholder={vPregunta.placeholder}
            comentarios={vPregunta.comentarios}
            valueNoCuenta={this.props.numero_cuenta}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );
      case "1":
        return (
          <InputTextArea
            id={vPregunta.id}
            pregunta={vPregunta.pregunta}
            entrada={vPregunta.entrada}
            placeholder={vPregunta.placeholder}
            comentarios={vPregunta.comentarios}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );
      case "2":
        return (
          <InputRadio
            id={vPregunta.id}
            id_compartido={vPregunta.id_compartido}
            pregunta={vPregunta.pregunta}
            respuestas={vPregunta.respuestas}
            comentarios={vPregunta.comentarios}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );
      case "3":
        return (
          <InputComboBox
            id={vPregunta.id}
            id_compartido={vPregunta.id_compartido}
            pregunta={vPregunta.pregunta}
            respuestas={vPregunta.respuestas}
            comentarios={vPregunta.comentarios}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );

      case "4":
        return (
          <InputDate
            id={vPregunta.id}
            pregunta={vPregunta.pregunta}
            comentarios={vPregunta.comentarios}
            obligatoria={vPregunta.obligatoria}
            value={
              this.state.infoDBTramite != null
                ? this.state.infoDBTramite[vPregunta.id]
                : ""
            }
          />
        );

      default:
    }
  }

  render() {
    return (
      <div className="sombra2 contenedo2">
        <Form onSubmit={this.enviar}>
          <Form.Group className="cen">
            <h1 className="titleMod">{this.props.titlo_Form}</h1>
            <div dangerouslySetInnerHTML={{ __html: this.props.descripcion }} />
            <>
              <br />
              <div className="styleLinea"></div>
              <br />
            </>
          </Form.Group>

          {this.state.form.map((vPregunta) => (
            <>{this.tipoPregunta(vPregunta)}</>
          ))}

          <div className="cen">
            <button className="btrn4">Enviar</button>
          </div>
        </Form>
      </div>
    );
  }
}
