import React, { Component } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

export default class Text_modalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_respuesta: "0",
    };
  }

  enviar = (evt) => {
    evt.preventDefault();
  };

  validarClick = (errors, value) => {
    if (
      !errors.numero_cuenta &&
      value.numero_cuenta != "" &&
      (value.numero_cuenta + "").length == 7
    ) {
      this.props.accion_aceptar();
      this.props.cambiarNoCuenta(value.numero_cuenta);
      this.props.Desactive();
    }
  };

  validateMoreError = (value, error) => {
    switch (value) {
      case "":
        if (error) {
          return true;
        } else {
          return true;
        }
      case "Entrada":
        return true;
      default:
        return false;
    }
  };
  validateMore(value) {
    if (value === "") {
      return <Form.Control.Feedback type="invalid"></Form.Control.Feedback>;
    } else {
      if (value === "rep") {
        return (
          <Form.Control.Feedback type="invalid">
            se repite am sorry
          </Form.Control.Feedback>
        );
      } else {
        return <Form.Control.Feedback></Form.Control.Feedback>;
      }
    }
  }

  verificarInfo = (evt) => {
    if (evt.altKey) {
      switch (evt.key) {
        case "G":
          alert("game iniciados");
          break;
        default:
          break;
      }
    }
  };

  /*
    En los props debe que contener:
    variant: Para el color del boton - boostrap,
    icon_ion: Nombre del icono sacado de la pagina ion
    estilo_className: Estilo del boton, ej. redondo (is-rounded) - bulma
    tamaño: Tamaño del boton ej. pequeño (is-small) - bulma 
    accion_aceptar: Accion que realizara cuando de en aceptar en el tipo 2, recive una funcion
    direccion: column or row
    titulo: titulo del modal.
    boton_texto: texto del boton para abrir el modal
    */

  render() {
    const schema = yup.object().shape({
      pregunta: yup.string().required(),
    });

    return (
      <>
        <div className={"modal " + this.props.stateModal}>
          <div className="modal-background"></div>
          <div key={this.props.key} className="modal-card">
            <header className="hemodal">
              <p className="">{this.props.titulo}</p>
              <button className="close" onClick={this.props.Desactive}></button>
            </header>
            <Formik
              validationSchema={schema}
              initialValues={{
                numero_cuenta: "",
              }}
            >
              {({ handleChange, values, errors }) => (
                <Form
                  onSubmit={() => this.props.accion_aceptar()}
                  autocomplete="off"
                >
                  <section className="bodyModal sclp2 ps2">
                    <>
                      <FloatingLabel
                        controlId="numero_cuenta"
                        label="Número de cuenta"
                      >
                        <Form.Control
                          onKeyDown={this.verificarInfo}
                          type="number"
                          placeholder="Número de cuenta"
                          name="numero_cuenta"
                          value={values.numero_cuenta}
                          onChange={handleChange}
                          isInvalid={this.validateMoreError(
                            values.numero_cuenta,
                            errors.numero_cuenta
                          )}
                          required
                          minlength="7"
                          maxlength="8"
                          min="1000000"
                          max="9999999"
                        />
                        {errors.numero_cuenta && (
                          <>{this.validateMore(values.numero_cuenta)}</>
                        )}
                        {!errors.numero_cuenta && (
                          <>{this.validateMore(values.numero_cuenta)}</>
                        )}
                      </FloatingLabel>
                    </>
                  </section>
                  <footer class="fomodal cenItem">
                    <button
                      className="btrn3"
                      type="submit"
                      onClick={() => this.validarClick(errors, values)}
                    >
                      <p className="">Continuar </p>
                    </button>
                  </footer>
                </Form>
              )}
            </Formik>
          </div>
        </div>{" "}
      </>
    );
  }
}
