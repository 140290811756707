/* eslint-disable array-callback-return */
import React, { Component } from "react";
import {
  Button,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import InputTextFile from "../../Componente/inputsForm/inputTextFile";
import InputTextArea from "../../Componente/inputsForm/inputTextArea";

export default class Text_modalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
      tipo_respuesta: "0",
      key: Date.now(),
      actualizarTiempoActividad: props.actualizarTiempoActividad,
    };
  }

  Active = () => {
    if (this.state.actualizarTiempoActividad !== undefined) {
      this.state.actualizarTiempoActividad();
    }
    this.setState({ active: "is-active", key: Date.now() });
    if (this.props.isCambiarEstado) {
      this.props.cambiarEstadoModal(true);
    }
  };

  Desactive = () => {
    if (this.state.actualizarTiempoActividad !== undefined) {
      this.state.actualizarTiempoActividad();
    }
    this.setState({ active: "" });
    if (this.props.isCambiarEstado) {
      this.props.cambiarEstadoModal(false);
    }
  };

  async componentDidMount() {
    if (this.props.boton_texto === "TextArea") {
      this.setState({ tipo_respuesta: "1" });
    }
  }

  enviar = (evt) => {
    evt.preventDefault();
  };

  isTextFile = (handleChange, errors, values) => {
    if (this.props.boton_texto === "TextField") {
      if (this.props.value !== null) {
        switch (this.props.value.entrada) {
          case "text":
            return (
              <Form.Select
                size=""
                className="mb-3"
                name="entrada"
                onChange={handleChange}
                isInvalid={this.validateMoreError(
                  values.entrada,
                  errors.entrada
                )}
                required
              >
                <option value="text" selected>
                  Textual
                </option>
                <option value="number">Numerica</option>
                <option value="email">Correo electronico</option>
                <option value="tel">Numero celular</option>
              </Form.Select>
            );
          case "number":
            return (
              <Form.Select
                size=""
                className="mb-3"
                name="entrada"
                onChange={handleChange}
                isInvalid={this.validateMoreError(
                  values.entrada,
                  errors.entrada
                )}
                required
              >
                <option value="text">Textual</option>
                <option value="number" selected>
                  Numerica
                </option>
                <option value="email">Correo electronico</option>
                <option value="tel">Numero celular</option>
              </Form.Select>
            );
          case "email":
            return (
              <Form.Select
                size=""
                className="mb-3"
                name="entrada"
                onChange={handleChange}
                isInvalid={this.validateMoreError(
                  values.entrada,
                  errors.entrada
                )}
                required
              >
                <option value="text">Textual</option>
                <option value="number">Numerica</option>
                <option value="email" selected>
                  Correo electronico
                </option>
                <option value="tel">Numero celular</option>
              </Form.Select>
            );
          case "tel":
            return (
              <Form.Select
                size=""
                className="mb-3"
                name="entrada"
                onChange={handleChange}
                isInvalid={this.validateMoreError(
                  values.entrada,
                  errors.entrada
                )}
                required
              >
                <option value="text">Textual</option>
                <option value="number">Numerica</option>
                <option value="email">Correo electronico</option>
                <option value="tel" selected>
                  Numero celular
                </option>
              </Form.Select>
            );

          default:
        }
      } else {
        return (
          <>
            <Form.Select
              size=""
              className="mb-3"
              name="entrada"
              onChange={handleChange}
              isInvalid={this.validateMoreError(values.entrada, errors.entrada)}
              required
            >
              <option disabled selected>
                Entrada
              </option>
              <option value="text">Textual</option>
              <option value="number">Numerica</option>
              <option value="email">Correo electronico</option>
              <option value="tel">Numero celular</option>
            </Form.Select>

            {errors.entrada && <>{this.validateMore(values.entrada)}</>}
            {!errors.entrada && <>{this.validateMore(values.entrada)}</>}
          </>
        );
      }
    }
  };

  validarClick = (errors, values) => {
    switch (this.state.tipo_respuesta) {
      case "1":
        if (!errors.id && !errors.pregunta) {
          const vItemN = {
            id: values.id,
            pregunta: values.pregunta,
            comentarios: values.comentarios,
            entrada: "",
            tipo_respuesta: this.state.tipo_respuesta,
            respuestas: values.respuestas,
            placeholder: values.placeholder,
            obligatoria: values.obligatoria.length === 0 ? "false" : "true",
            guardar_info: values.guardar_info.length === 0 ? "false" : "true",
          };
          this.props.accion_aceptar(
            vItemN,
            this.props.value !== null ? true : false
          );
          this.Desactive();
        }
        break;

      default:
        if (
          !errors.id &&
          !errors.pregunta &&
          !errors.entrada &&
          values.entrada !== "Entrada"
        ) {
          const vItemN = {
            id: values.id,
            texto: values.pregunta,
            pregunta: values.pregunta,
            comentarios: values.comentarios,
            entrada: values.entrada,
            tipo_respuesta: this.state.tipo_respuesta,
            respuestas: values.respuestas,
            placeholder: values.placeholder,
            obligatoria: values.obligatoria.length === 0 ? "false" : "true",
            guardar_info: values.guardar_info.length === 0 ? "false" : "true",
          };
          this.props.accion_aceptar(
            vItemN,
            this.props.value !== null ? true : false
          );
          this.Desactive();
        }
        break;
    }
  };

  buscarId = (value) => {
    if (this.props.items) {
      var encontrado = false;
      this.props.items.map((item) => {
        if (item.id === value) {
          if (this.props.value !== null) {
            if (this.props.value.id === value) {
              encontrado = false;
              return false;
            } else {
              encontrado = true;
              return true;
            }
          } else {
            encontrado = true;
            return true;
          }
        }
      });
      return encontrado;
    }
    return false;
  };

  validateIDError = (value, error) => {
    if (this.buscarId(value)) {
      return true;
    } else {
      switch (value) {
        case "":
          if (error) {
            return true;
          } else {
            return true;
          }
        default:
          return false;
      }
    }
  };
  validateId(value) {
    if (value === "") {
      return <Form.Control.Feedback type="invalid"></Form.Control.Feedback>;
    } else {
      if (this.buscarId(value)) {
        return (
          <Form.Control.Feedback type="invalid">
            Esta id ya es utilizada, escoja otra.
          </Form.Control.Feedback>
        );
      } else {
        return <Form.Control.Feedback></Form.Control.Feedback>;
      }
    }
  }
  validateMoreError = (value, error) => {
    switch (value) {
      case "":
        if (error) {
          return true;
        } else {
          return true;
        }
      case "Entrada":
        return true;
      default:
        return false;
    }
  };
  validateMore(value) {
    if (value === "") {
      return <Form.Control.Feedback type="invalid"></Form.Control.Feedback>;
    } else {
      if (value === "rep") {
        return (
          <Form.Control.Feedback type="invalid">
            se repite am sorry
          </Form.Control.Feedback>
        );
      } else {
        return <Form.Control.Feedback></Form.Control.Feedback>;
      }
    }
  }

  renderEjemplo = () => {
    if (this.props.boton_texto === "TextArea") {
      return (
        <InputTextArea
          id={"textArea"}
          pregunta={"Ejemplo TextArea"}
          entrada={"text"}
          placeholder={""}
          comentarios={""}
          value={""}
        />
      );
    } else {
      return (
        <InputTextFile
          id={"textFile"}
          pregunta={"Ejemplo TextField"}
          entrada={"text"}
          placeholder={""}
          comentarios={""}
          value={""}
        />
      );
    }
  };

  showButton = () => {
    if (this.props.showButton) {
      return (
        <OverlayTrigger
          trigger="hover"
          key="left"
          placement="bottom"
          overlay={
            <Popover id={`popover-positioned-left`}>
              <Popover.Header as="h3">{this.props.boton_texto}</Popover.Header>
              <Popover.Body>{this.renderEjemplo()}</Popover.Body>
            </Popover>
          }
        >
          <Button
            variant={this.props.variant}
            onClick={this.Active}
            className={
              "button is-flex is-flex-direction-" +
              this.props.direccion +
              " " +
              this.props.estilo_className +
              " " +
              this.props.tamaño
            }
          >
            <span>
              <ion-icon name={this.props.icon_ion}></ion-icon>
            </span>
            <p>{this.props.boton_texto}</p>
          </Button>
        </OverlayTrigger>
      );
    }
  };

  showButtonA = () => {
    if (this.props.showButton === true) {
      return this.state.active;
    } else {
      return this.props.stateModal;
    }
  };

  valueDefault = () => {
    if (this.props.value !== null) {
      return {
        id: this.props.value.id,
        pregunta: this.props.value.pregunta,
        comentarios: this.props.value.comentarios,
        entrada: this.props.value.entrada,
        respuestas: this.props.value.respuestas,
        placeholder: this.props.value.placeholder,
        obligatoria: this.props.value.obligatoria === "true" ? "1" : "",
        guardar_info: this.props.value.guardar_info === "true" ? "1" : "",
      };
    } else {
      return {
        id: "",
        pregunta: "",
        comentarios: "",
        entrada: "Entrada",
        respuestas: "",
        placeholder: "",
        obligatoria: "1",
        guardar_info: "",
      };
    }
  };

  quitarEspacioId = () => {};

  /*
    En los props debe que contener:
    variant: Para el color del boton - boostrap,
    icon_ion: Nombre del icono sacado de la pagina ion
    estilo_className: Estilo del boton, ej. redondo (is-rounded) - bulma
    tamaño: Tamaño del boton ej. pequeño (is-small) - bulma 
    accion_aceptar: Accion que realizara cuando de en aceptar en el tipo 2, recive una funcion
    direccion: column or row
    titulo: titulo del modal.
    boton_texto: texto del boton para abrir el modal
    */

  render() {
    const schema = yup.object().shape({
      id: yup
        .string()
        .min(1, "")
        .required("Debe escribir el id de la pregunta."),
      pregunta: yup.string().required(),
      comentarios: yup.string().required(),
      entrada: yup.string().required(),
      respuestas: yup.string().required(),
      placeholder: yup.string().required(),
    });

    return (
      <>
        {this.showButton()}
        <div className={"modal " + this.showButtonA()} key={this.state.key}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="hemodal">
              <p className="">{this.props.titulo}</p>
              <button className="close" onClick={this.Desactive}></button>
            </header>
            <Formik
              key={this.state.key}
              validationSchema={schema}
              initialValues={this.valueDefault()}
            >
              {({ handleChange, values, errors }) => (
                <Form onSubmit={this.enviar} autocomplete="off">
                  <section className="bodyModal2 sclp2 ps2">
                    <>
                      <FloatingLabel
                        controlId="id_pregunta"
                        label="Identificador de la pregunta"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="id"
                          name="id"
                          value={values.id.replace(" ", "_").trim()}
                          onChange={handleChange("id")}
                          isInvalid={this.validateIDError(values.id, errors.id)}
                          required
                        />
                        {errors.id && <>{this.validateId(values.id)}</>}
                        {!errors.id && <>{this.validateId(values.id)}</>}
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="pregunta"
                        label="Ingrese la pregunta"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="pregunta"
                          name="pregunta"
                          value={values.pregunta}
                          onChange={handleChange}
                          isInvalid={this.validateMoreError(
                            values.pregunta,
                            errors.pregunta
                          )}
                          required
                        />
                        {errors.pregunta && (
                          <>{this.validateMore(values.pregunta)}</>
                        )}
                        {!errors.pregunta && (
                          <>{this.validateMore(values.pregunta)}</>
                        )}
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="comentarios"
                        label="Comentarios de la pregunta"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="comentarios"
                          name="comentarios"
                          value={values.comentarios}
                          onChange={handleChange}
                        />
                      </FloatingLabel>

                      {this.isTextFile(handleChange, errors, values)}

                      <FloatingLabel
                        controlId="placeholder"
                        label="Placeholder  de la pregunta"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="placeholder"
                          name="placeholder"
                          value={values.placeholder}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                      <div className="margen_text">
                        <p>Importancia de la pregunta...</p>
                        <div>
                          <Form.Check
                            type="switch"
                            id="obligatoria"
                            label="Es obligatorio que se contesté."
                            name="obligatoria"
                            onChange={handleChange}
                            checked={
                              values.obligatoria.length === 1 ? true : false
                            }
                          />
                        </div>
                      </div>
                      <br />
                      <div className="margen_text">
                        <p>Sobre el trato de la información...</p>
                        <div>
                          <Form.Check
                            type="switch"
                            id="guardar_info"
                            label="Guardar información para estadisticas"
                            name="guardar_info"
                            onChange={handleChange}
                            checked={
                              values.guardar_info.length === 1 ? true : false
                            }
                          />
                        </div>
                      </div>
                    </>
                  </section>
                  <footer class="fomodal cenItem">
                    <button
                      className="btrn3"
                      type="submit"
                      onClick={() => this.validarClick(errors, values)}
                    >
                      <p>Aceptar</p>
                    </button>
                  </footer>
                </Form>
              )}
            </Formik>
          </div>
        </div>{" "}
      </>
    );
  }
}
