var rutaLocal = "https://api.serviciosocial.tecnologinc.com";

/*
Config_Tramite y subirArchivos() - Elimina el archvio enviarCorreoPhp.php
*/
export async function eliminarArchivo(nombreEliminar) {
  var dataEliminar = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  dataEliminar.append("nombre_tramiteOld", nombreEliminar);
  dataEliminar.append("cookie", localStorage.getItem("cookie"));
  await fetch(vURL + "/backend/php/eliminarPHPenviarCorreo.php", {
    mode: "cors",
    method: "POST",
    body: dataEliminar,
  });
}

/*
Creacion_admin
*/
export async function subirArchivos(
  archivos,
  tramite,
  info_formulario,
  isModificaNombre
) {
  var data = new FormData();
  var cont = 0;
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  data.append("tramite", JSON.stringify(tramite));
  data.append("cookie", localStorage.getItem("cookie"));
  for (const archivo in archivos) {
    if (Object.hasOwnProperty.call(archivos, archivo)) {
      data.append("archivo" + cont, archivos[cont]);
      cont++;
    }
  }
  data.append("numArchivos", cont);
  data.append("numPreguntas", info_formulario.length);
  const response = await fetch(vURL + "/backend/php/crearPHPenviarCorreo.php", {
    mode: "cors",
    method: "POST",
    body: data,
  });
  return response.json();
}

/*
Crear base de datos de forma dinamica
*/
export async function crearDB(tramite) {
  var data = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  data.append("tramite", JSON.stringify(tramite));
  data.append("cookie", localStorage.getItem("cookie"));

  const response = await fetch(
    vURL + "/backend/php/db/crearTablaDBDynamic.php",
    {
      mode: "cors",
      method: "POST",
      body: data,
    }
  );

  return await response.text();
}
/*
Modificar base de datos
*/
export async function modificarDB(idTramite, movimientos, tramite) {
  var data = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  data.append("idTramite", idTramite);
  data.append("movimientos", JSON.stringify(movimientos));
  data.append("tramite", JSON.stringify(tramite));
  data.append("cookie", localStorage.getItem("cookie"));

  const response = await fetch(vURL + "/backend/php/db/editarTabDyn.php", {
    mode: "cors",
    method: "POST",
    body: data,
  });

  return await response.text();
}

/*
config_tramites - Descargar base de datos
*/
export async function descargarDatosXLM(tramites) {
  var data = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  data.append("tramites", JSON.stringify(tramites));
  data.append("cookie", localStorage.getItem("cookie"));
  const infoApi = await fetch(vURL + "/backend/php/db/descargarDatos.php", {
    mode: "cors",
    method: "POST",
    body: data,
  }).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "Servicio Social Estadistica.xls";
      a.click();
      a.remove();
    });
  });
  return "1";
}

/*
Home_Admin - Config_Pagina
*/
export async function readJSON(tipo) {
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  const res = await fetch(
    vURL +
      "/backend/php/FormRead.php?tipo=" +
      tipo +
      "&cookie=" +
      localStorage.getItem("cookie"),
    {
      method: "GET",
      mode: "cors",
    }
  );
  return await res.json();
}

/*
Interfaz_Alimno
*/
export async function readJSONAlumno(tipo) {
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  const res = await fetch(
    vURL + "/backend/php/FormReadAlumno.php?tipo=" + tipo
  );
  return await res.json();
}

/*
Home_Admin - verifica si la sesion esta activa
*/
export async function mEstadoSesion(tipo) {
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  const res = await fetch(
    vURL +
      "/backend/php/db/sesion/actividad.php?tipo="+tipo+"&cookie=" +
      localStorage.getItem("cookie"),
    {
      method: "GET",
      mode: "cors",
    }
  );
  return await res.json();
}
/*
Home_Admin - activa el servicio para detectar si la sesion esta activa o no cuando se cierra el navegador
*/
export async function mActivarVerificador() {
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  await fetch(vURL + "/backend/php/db/sesion/activarVerificador.php", {
    method: "POST",
    mode: "cors",
  });
}

/*
Config_tramites - cerrara la sesion activa
*/
export async function mCerrarSesion() {
  var data = new FormData();
  data.append("cookie", localStorage.getItem("cookie"));
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  const res = await fetch(vURL + "/backend/php/db/sesion/cerrar.php", {
    method: "POST",
    mode: "cors",
    body: data,
  });
  return await res.json();
}

/*
Home_Admin
*/
export async function escribirFormatoJSON(
  vTramitesN,
  vTramiteDel,
  vTipoAccion
) {
  var data = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  data.append("tramites", JSON.stringify(vTramitesN));
  data.append("tramiteDel", vTramiteDel);
  data.append("accion", vTipoAccion);
  data.append("cookie", localStorage.getItem("cookie"));

  const response = await fetch(vURL + "/backend/php/escribirFormatoJSON.php", {
    mode: "no-cors",
    method: "POST",
    body: data,
  });
  return await response.text();
}

/*
Home_Admin
*/
export async function eliminarDB(delTramite) {
  var data = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  data.append("delTramite", JSON.stringify(delTramite));
  data.append("cookie", localStorage.getItem("cookie"));

  const response = await fetch(vURL + "/backend/php/db/eliminarTabDyn.php", {
    mode: "no-cors",
    method: "POST",
    body: data,
  });

  return await response.text();
}

/* 
Config_Pagina
*/
export async function escribirpag_info(vPag_info) {
  var data = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  data.append("pag_info", JSON.stringify(vPag_info));
  data.append("cookie", localStorage.getItem("cookie"));

  const response = await fetch(vURL + "/backend/php/escribirpag_info.php", {
    mode: "no-cors",
    method: "POST",
    body: data,
  });
  return await response.text();
}

/*
Login_Form
*/
export async function login(usuario, contraseña) {
  try {
    var vURL =
      window.location.origin !== "http://localhost:3000" &&
      window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
        ? window.location.origin
        : rutaLocal;
    const res = await fetch(
      vURL + "/backend/php/db/LogInForm.php?user=" + usuario
    );

    return await res.json();
  } catch (error) {
    return error;
  }
}

/*
AddAccount.jsx - Add user
*/
export async function addAcount(usuario, email, contraseña) {
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  const res = await fetch(
    vURL +
      "/backend/php/db/addUsers.php?user=" +
      usuario +
      "&email=" +
      email +
      "&passw=" +
      contraseña
  );

  return await res.text();
}

/*
Alumno - Crear formulario
*/
/*
FormCreate.jsx - Enviar formulario contestado
*/
export async function enviarForm(info, tramite_id, tramite_nombre) {
  var data = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  for (let index = 0; index < info.length - 1; index++) {
    if (info[index].name.length > 0) {
      if (info[index].type === "radio") {
        if (info[index].lang === "false") {
          if (info[index].checked) {
            data.append(info[index].name, info[index].value);
          }
        } else {
          if (info[index].checked) {
            data.append(info[index].id, "X");
          } else {
            data.append(info[index].id, " ");
          }
        }
      } else {
        if (info[index].type === "select-one") {
          if (info[index].lang === "false") {
            data.append(info[index].id, info[index].value);
          } else {
            for (let i = 0; i < info[index].length; i++) {
              if (info[index].value === info[index][i].value) {
                data.append(info[index][i].id, "X");
              } else {
                data.append(info[index][i].id, " ");
              }
            }
          }
        } else {
          data.append(info[index].id, info[index].value);
        }
      }
    }
  }

  const response = await fetch(
    vURL + "/backend/php/FormEnviarCorreo/" + tramite_id + ".php",
    {
      mode: "no-cors",
      method: "POST",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=AaB03x" +
          "--AaB03x" +
          "Content-Disposition: file" +
          "Content-Type: png" +
          "Content-Transfer-Encoding: binary" +
          "...data... " +
          "--AaB03x--",
        Accept: "application/json",
        type: "formData",
      },
      body: data,
    }
  );
  console.log(await response.text());

  return await response.text();
}

/*
FormCreate.jsx - Cargar datos a la base de datos
*/
export async function infoDB(info, tramite_id, tramite_nombre) {
  var data = new FormData();
  var vURL =
    window.location.origin !== "http://localhost:3000" &&
    window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
      ? window.location.origin
      : rutaLocal;
  for (let index = 0; index < info.length - 1; index++) {
    if (info[index].name.length > 0) {
      if (info[index].type === "radio") {
        if (info[index].checked) {
          data.append(info[index].name, info[index].value);
        }
      } else {
        if (info[index].type === "select-one") {
          if (info[index].lang === "false") {
            data.append(info[index].id, info[index].value);
          } else {
            for (let i = 0; i < info[index].length; i++) {
              if (info[index].value === info[index][i].value) {
                data.append(info[index].id, info[index][i].value);
                break;
              }
            }
          }
        } else {
          data.append(info[index].id, info[index].value);
        }
      }
    }
  }

  const response = await fetch(
    vURL + "/backend/php/db/dbDynamic/db_" + tramite_id + ".php",
    {
      mode: "no-cors",
      method: "POST",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=AaB03x" +
          "--AaB03x" +
          "Content-Disposition: file" +
          "Content-Type: png" +
          "Content-Transfer-Encoding: binary" +
          "...data... " +
          "--AaB03x--",
        Accept: "application/json",
        type: "formData",
      },
      body: data,
    }
  );

  return await response.text();
}

/*
FormCreate.jsx -Buscar alumno
*/
export async function searchAlumno(no_cuenta_alumno, idTramite) {
  try {
    var vURL =
      window.location.origin !== "http://localhost:3000" &&
      window.location.origin !== "http://serviciosocial.tecnologinc.com:3000"
        ? window.location.origin
        : rutaLocal;
    const res = await fetch(
      vURL +
        "/backend/php/db/searchAlumno.php?no_cuenta_alumno=" +
        no_cuenta_alumno +
        "&idTramite=" +
        idTramite
    );
    return await res.json();
  } catch (error) {
    return error;
  }
}
