import React, { Component } from "react";

import { CardColumns, Card, Button } from "react-bootstrap";

import Modal from "./Modals/Modal";

export default class CardInfo extends Component {
  state = {
    formulario: [],
    cargando: true,
  };

  async componentDidMount() {
    this.setState({ formulario: this.props.tramite });
  }

  botonFooter = () => {
    if (this.props.isBotonDefault) {
      return (
        <Button
          variant="outline-info"
          onClick={() =>
            this.props.cambiarPathF("Formulario", this.props.tramite)
          }
        >
          {this.props.texto_boton}
        </Button>
      );
    } else {
      return this.props.botonCardFooter(
        this.props.texto_boton,
        this.props.tramite
      );
    }
  };

  render() {
    return (
      <>
        <CardColumns>
          <Card border="dark" className="text-center">
            <Card.Header className="box boxMd">
              <Card.Title>
                <div className="titelMd">{this.props.tituloCard}</div>
              </Card.Title>
              <div className="derItem">
                {
                  //aqui es modificar el ? lo de adentro
                }
                <Modal
                  showButton={true}
                  isIcono={true}
                  icon_ion="help-outline"
                  variant="outline-warning"
                  estilo_className="sigNo"
                  tamaño="tamNo"
                  tipo="1"
                  isText={false}
                  titulo={this.props.titulo_ModalInfo}
                  descripcion={this.props.cuerpo_ModalInfo}
                  cambiarEstadoModal={this.props.cambiarEstadoModal}
                  classBody="bodyModal3"
                />
              </div>
            </Card.Header>
            <div className="sclp ps">
              <Card.Body>
                <Card.Text>{this.props.cuerpoCard}</Card.Text>
              </Card.Body>
            </div>
            <Card.Footer className="is-align-items-center is-flex-direction-column">
              {this.botonFooter()}
            </Card.Footer>
          </Card>
        </CardColumns>
      </>
    );
  }
}
