import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Modal from "../Modals/Modal";
import "../showinfo.css";

export default class Ordenar_Opciones extends Component {
  state = {
    active: false,
  };

  Active = () => {
    this.setState({ active: "is-active" });
  };

  Desactive = () => {
    this.setState({ active: "" });
  };

  eliminar = () => {
    this.props.eliminarAccion(this.props.id_item);
  };

  muestraModal = () => (
    <Modal
      variant="outline-warning"
      icon_ion="accessibility-outline"
      estilo_className="is-rounded"
      tamaño="is-large"
      titulo={this.props.titulo_Modal}
      descripcion={this.props.descripcion_Modal}
      showButton={false}
      tipo="2"
      accion_aceptar={this.eliminar}
      stateModal={this.state.active}
      Desactive={this.Desactive}
      isText={false}
    >
      Eliminar
    </Modal>
  );

  botonMore = () => {
    if (
      this.props.id_item !== "correo_alumno" &&
      this.props.id_item !== "no_cuenta_alumno"
    ) {
      return (
        <OverlayTrigger
          class
          trigger="focus"
          key={1}
          placement="bottom"
          delay={{ show: 50, hide: 100 }}
          overlay={
            <Popover id={`popover-id-1`}>
              <Popover.Body>
                <div className="cajitaFlex">
                  <button
                    onClick={this.props.Accion_Botton_Editar}
                    className="sombra3 btrn3"
                  >
                    <spam>Editar</spam>
                    <spam class={"icon "}>
                      <ion-icon name="pencil"></ion-icon>
                    </spam>
                  </button>
                  <button onClick={this.Active} className={"sombra3 btrn3c"}>
                    <span>Eliminar</span>
                    <span class={"icon "}>
                      <ion-icon name="trash"></ion-icon>
                    </span>
                  </button>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <button className="button">
            <ion-icon name="ellipsis-vertical"></ion-icon>
          </button>
        </OverlayTrigger>
      );
    }
  };

  render() {
    return (
      <div className="level">
        <div className="cen">
          <div className="level-item">
            <p>{this.props.nombre_item}</p>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">{this.botonMore()}</div>
        </div>
        {this.muestraModal()}
      </div>
    );
  }
}
