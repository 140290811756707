import React, { Component } from "react";

import { Col, Form } from "react-bootstrap";

export default class inputRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: true,
    };
  }
  isRequired = (vRespuesta) => {
    if (this.props.obligatoria == "true") {
      if (this.props.value == vRespuesta.opc) {
        return (
          <Form.Check
            required
            type="radio"
            label={vRespuesta.opc}
            name={this.props.id}
            id={vRespuesta.id}
            lang={this.props.id_compartido}
            value={vRespuesta.opc}
            checked={this.state.check}
            onClick={() => this.setState({ check: true })}
          />
        );
      } else {
        return (
          <Form.Check
            required
            type="radio"
            label={vRespuesta.opc}
            name={this.props.id}
            id={vRespuesta.id}
            lang={this.props.id_compartido}
            value={vRespuesta.opc}
            onClick={() => this.setState({ check: false })}
          />
        );
      }
    } else {
      if (this.props.value == vRespuesta.opc) {
        return (
          <Form.Check
            type="radio"
            label={vRespuesta.opc}
            name={this.props.id}
            id={vRespuesta.id}
            lang={this.props.id_compartido}
            value={vRespuesta.opc}
            checked={this.state.check}
            onClick={() => this.setState({ check: true })}
          />
        );
      } else {
        return (
          <Form.Check
            type="radio"
            label={vRespuesta.opc}
            name={this.props.id}
            id={vRespuesta.id}
            lang={this.props.id_compartido}
            value={vRespuesta.opc}
            onClick={() => this.setState({ check: false })}
          />
        );
      }
    }
  };

  render() {
    return (
      <Form.Group className="sombra3 pad2">
        <Form.Label>{this.props.pregunta}</Form.Label>
        <div className="hr" />
        <fieldset className="radioF">
          <Form.Group
            className={this.props.respuestas.length >= 6 ? "radio" : "radio2"}
          >
            {this.props.respuestas.map((vRespuesta) => (
              <>{this.isRequired(vRespuesta)}</>
            ))}
          </Form.Group>
        </fieldset>
        <Form.Text>{this.props.comentarios}</Form.Text>
      </Form.Group>
    );
  }
}
